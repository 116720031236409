import React, { useState, useEffect } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import Projects from '../api/Projects';
import ProjectCard from '../components/ProjectList/ProjectCard';
import statesList from '../utils/StatesList.json';
import specialityList from '../utils/SpecialityList.json';
import NoProjectsFound from '../components/ProjectList/NoProjectsFound';
import IProjectItem from '../interfaces/IProjectItem';
import IProjects from '../interfaces/IProjects';
import IStateItem from '../interfaces/IStateItem';
import IFilterProjects from '../interfaces/IFilterProjects';
import '../assets/css/ProjectList.css';
import { useStore } from '../store/user';

const ProjectList = () => {
    const [listProjects, setListProjects] = useState<IProjects>();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filterParams, setFilterParams] = useState<Partial<IFilterProjects>>({
        orderBy: "Newest",
        limit: 25,
    });
    const [listStatesCheck, setListStatesCheck] = useState<Array<string>>([]);
    const [region, setRegion] = useState<string>("");
    const [selectType, setSelectType] = useState<string>("");
    const [showTypeInput, setShowTypeInput] = useState<boolean>(false);
    const [showClearBtn, setShowClearBtn] = useState<boolean>(false);
    const user = useStore((state: any) => state.user);
    const fetchUser = useStore((state: any) => state.fetchUser);

    useEffect(() => {
        filterStates();
    }, [listStatesCheck])

    useEffect(() => {
        statesList?.states?.map((state: IStateItem) => {
            if (state.region == region) {
                listStatesCheck.push(state.name);
            }
            if (state.region != region) {
                setListStatesCheck(listStatesCheck.filter(item => item != state.name));
            }
        })
    }, [region])

    const handleStates = (name: string) => {
        if (listStatesCheck.includes(name)) {
            setListStatesCheck(listStatesCheck.filter(item => item !== name));
        } else {
            setListStatesCheck([...listStatesCheck, name]);
        }
    }

    const filterStates = () => {
        let statesString = listStatesCheck.toString();
        let statesFilter = statesString.replaceAll(",", " ")
        setFilterParams({ ...filterParams, state: statesFilter })
    }

    const fetchProjects = async () => {
        if (filterParams.state == "") delete filterParams.state;
        if (filterParams.typeOfResearch == "") delete filterParams.typeOfResearch;
        const projectsItems = await Projects.findAll(setLoading, filterParams);
        setTotalPages(projectsItems.pages);
        if (localStorage.getItem("@fmr/userLogged") == "true") {
            fetchUser(user)
        }
        projectsItems.projects.length == 0 ? setListProjects({ projects: undefined }) : setListProjects({ projects: projectsItems.projects });
    };

    useEffect(() => {
        fetchProjects();
    }, [filterParams.page, filterParams.limit, filterParams.orderBy])

    const handleRegion = (region: string) => {
        setListStatesCheck([]);
        setRegion(region);
    }

    const itemsProjects = () => {
        if (listProjects?.projects == undefined) {
            return <NoProjectsFound message="No projects projects found" />
        } else {
            return listProjects?.projects?.map((project: IProjectItem) => {
                return <ProjectCard
                    acceptedApplicants={project.acceptedApplicants.length}
                    projectTitle={project.projectTitle} 
                    numberOfStudents={project.numberOfStudents}
                    maxNumberOfStudents={project.maxNumberOfStudents}
                    projectDescription={project.projectDescription}
                    createdAt={project.createdAt}
                    _id={project._id}
                    status={project.status}
                    speciality={project.speciality}
                    typeOfResearch={project.typeOfResearch}
                    school={project.school}
                    key={project._id}
                    refetch={() => {return}}
                />;
            })
        }
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setFilterParams({ ...filterParams, page: value });
    };

    const handleType = () => {
        if (selectType == "Other") {
            setShowTypeInput(true);
            setFilterParams({ ...filterParams, typeOfResearch: '' });
        } else {
            setShowTypeInput(false);
            setFilterParams({ ...filterParams, typeOfResearch: selectType });
        };
    }

    useEffect(() => {
        handleType();
    }, [selectType])

    useEffect(() => {
        handleClearBtn();
    }, [filterParams.speciality, filterParams.state, filterParams.typeOfResearch])

    const handleClearBtn = () => {
        if (filterParams.speciality || filterParams.state || filterParams.typeOfResearch) {
            setShowClearBtn(true);
        } else {
            setShowClearBtn(false);
        }
    }

    const clearFilters = () => {
        window.location.reload();
    }

    return (
        <div id="list-projects">
            <div className="content-projects">
                <div className="header-projects">
                    <div>
                        <h1 className="title-projects">Search</h1>
                    </div>
                    <div className={`sort-projects ${showClearBtn}`}>
                        {showClearBtn &&
                            <span onClick={clearFilters} className="clear-btn">Clear Filters</span>
                        }
                        <FormControl className="item-show">
                            <InputLabel>Show</InputLabel>
                            <Select
                                label="Show"
                                value={filterParams.limit || ''}
                                onChange={e => setFilterParams({
                                    ...filterParams,
                                    limit: e.target.value as number
                                })}
                            >
                                <MenuItem value={25}>25 Projects</MenuItem>
                                <MenuItem value={50}>50 Projects</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="item-sort">
                            <InputLabel>Sort</InputLabel>
                            <Select
                                label="Sort"
                                value={filterParams.orderBy || ''}
                                onChange={e => setFilterParams({
                                    ...filterParams,
                                    orderBy: e.target.value
                                })}
                            >
                                <MenuItem value="Oldest">Oldest</MenuItem>
                                <MenuItem value="Newest">Newest</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="body-projects">
                    <div className="filters-projects">
                        <FormControl className="item-filter" fullWidth>
                            <InputLabel>Specialty</InputLabel>
                            <Select
                                label="Specialty"
                                value={filterParams.speciality || ''}
                                onChange={e => setFilterParams({
                                    ...filterParams,
                                    speciality: e.target.value
                                })}
                            >
                                {specialityList?.specialities?.map((speciality: string, index) => {
                                    return <MenuItem value={speciality} key={index}>{speciality}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className="item-filter" fullWidth>
                            <InputLabel>Region</InputLabel>
                            <Select
                                label="Region"
                                value={region}
                                onChange={(e) => (handleRegion(e.target.value))}
                            >
                                <MenuItem value="West">West</MenuItem>
                                <MenuItem value="Midwest">Midwest</MenuItem>
                                <MenuItem value="Southeast">Southeast</MenuItem>
                                <MenuItem value="Northeast">Northeast</MenuItem>
                            </Select>
                        </FormControl>

                        <Accordion className="item-filter state-filter">
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p className="subtitle">State</p>
                            </AccordionSummary>
                            <AccordionDetails className="states-list">
                                {statesList?.states?.map((state: IStateItem, index) => {
                                    return <FormControlLabel key={index} className="state-item" control={<Checkbox checked={listStatesCheck.includes(state.name)} onClick={() => (handleStates(state.name))} />} label={state.name} />
                                })}
                            </AccordionDetails>
                        </Accordion>

                        <FormControl className="item-filter" fullWidth>
                            <InputLabel>Type of Research Project</InputLabel>
                            <Select
                                label="Type of Research Project"
                                value={selectType || ''}
                                onChange={(e) => setSelectType(e.target.value)}
                            >
                                <MenuItem value="Case Report/Case Series">Case Report/Case Series</MenuItem>
                                <MenuItem value="Literature review">Literature review</MenuItem>
                                <MenuItem value="Original research paper">Original research paper</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>

                        {showTypeInput &&
                            <FormControl className="item-filter" fullWidth>
                                <TextField
                                    required
                                    id="outlined"
                                    type="text"
                                    label="Other type"
                                    value={filterParams.typeOfResearch || ''}
                                    onChange={e => setFilterParams({
                                        ...filterParams,
                                        typeOfResearch: e.target.value
                                    })}
                                />
                            </FormControl>
                        }
                        <Button onClick={fetchProjects} className="btn-filter" fullWidth variant="contained">search</Button>
                    </div>
                    <div className="projects-list">
                        {loading ? <CircularProgress className="loading-projects" /> : itemsProjects()}
                        <Pagination
                            className="projects-pagination"
                            page={filterParams.page || 1}
                            onChange={handlePageChange}
                            count={totalPages}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectList;