import React from 'react';
import Button from '@mui/material/Button';
import "../../assets/css/HomeResources.css";

const HomeResources = () => {
    return (
        <div id="home-resources">
            <div className="content-resources">
                <h2 className="title-resources">Resources to help write up a research project</h2>
                <div className="box-btn">
                    <Button href="/research-resources" className="btn-resources" variant="contained">Research Resources</Button>
                </div>
            </div>
        </div>
    )
}

export default HomeResources;