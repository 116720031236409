import React from 'react';

import PageTemplate from '../components/PageTemplate';
import PrivacyPolicyPage from './PrivacyPollicyPage';
import LegalDisclaimerPage from './LegalDisclaimerPage';
import CookiePolicyPage from './CookiePolicyPage';
import CopyRightPolicyPage from './CopyRightPolicyPage';

const TermsAndConditionsPage = ({ isModal }: { isModal: boolean }) => {
    return (
        <>
            <PageTemplate title={`${!isModal ? "Terms and Conditions" : ""}`}>
                <div id="terms-content">
                    <p className="text">
                        You are required to agree to the Terms and Conditions, legal disclaimer, privacy policy, cookie policy, and copyright policy prior to use of the website. We may modify Terms and Conditions at any time by posting the revised “Terms & Conditions” at: findmyresearch.com. All changes will be effective immediately after we post them. You are required to agree to the changes to the Terms and Conditions prior to further use of the website. For purposes of these terms and conditions, Findmyresearch LLC and Findmyreasearch.com are the same entity.
                    </p>

                    <p className="subtitle">1. PLATFORM</p>
                    <p className="text">
                        Findmyresearch.com operates as a platform connecting users searching for clinical research to assist with (i.e. students/trainees), and users who are searching for assistance in completing their research projects (i.e. project owners/posters).
                    </p>

                    <p className="subtitle">2. MEMBERSHIP ELIGIBILITY</p>

                    <p className="text"><strong>2.1</strong> If you are a minor i.e. under the age of 18 years, you will not be able to register as a user and/or member of Findmyresearch.com and cannot transact on or use the website. If you are above the age of 18, you can use the site or services only in conjunction with, and under the supervision of, your parent or guardian who has agreed to the Terms. If you are the parent or legal guardian of a child under the age of 18, you may use the site or services on behalf of such minor child. </p>

                    <p className="text"><strong>2.2</strong> By using the site or services on behalf of a minor child, you represent and warrant that you are the parent or legal guardian of such child. If you do not qualify under these terms, do not use the site or services. Membership in the services is void where prohibited by applicable law, and the right to access the site is revoked in such jurisdictions. </p>

                    <p className="text"><strong>2.3</strong> By using the site and/or the services, you represent and warrant that you have the right, authority, and capacity to enter into these Terms and to abide by all of the Terms set forth herein. You agree: (a) to provide accurate, current, and complete information about you as may be prompted by a registration form on the website ("Registration Data"); (b) to maintain the security of your password and identification; (c) to maintain and promptly update the Registration Data and any information you provide to Findmyresearch.com, to keep it accurate, current and complete; and (e) to accept all risks of unauthorized access to information and Registration Data.</p>


                    <p className="subtitle">3. PROVIDE ACCURATE INFORMATION</p>
                    <p className="text">Information you provide is defined by any information you provide to us or other users in the registration, offering or posting process, in any public message area or through any email feature. You are solely responsible for your information, and we act as a passive conduit for your information. Information you provide or any requests and services you post on the site should not: (1) be false, inaccurate or misleading; (2) be fraudulent; (3) infringe any third party's copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy; (4) violate any law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, discrimination or false advertising); (5) be defamatory, trade libelous, unlawfully threatening or unlawfully harassing; (6) be obscene (7) contain any viruses or other computer programming routines that may damage, detrimentally interfere with, intercept or expropriate any system, data or personal information; (8) create liability for us or cause us to lose (in whole or in part) the services; (9) link directly or indirectly to or include descriptions of goods or services that: are prohibited under this Agreement; You may not post any request on the site (or consummate any transaction that was initiated using our service) that could cause us to violate any applicable law, statute, ordinance or regulation. </p>

                    <p className="subtitle">4. BREACH</p>
                    <p className="text">Findmyresearch.com may limit your activity, immediately remove your request postings, issue a warning to our users of your actions, suspend or terminate your membership and refuse to provide our services to you if: (1) you breach this Agreement or the documents it incorporates by reference; (2) we are unable to verify or authenticate any information to us; (3) we believe that your actions may cause financial loss or legal liability for you, our users or us.</p>

                    <p className="subtitle">5. SERVICE RESTRICTIONS </p>
                    <p className="text"><strong>5.1</strong>You shall not access, store, distribute or transmit any viruses, or any material during the course of your use of the site that: (a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive; (b) facilitates illegal activity; (c) depicts sexually explicit images; (d) promotes unlawful violence; (e) is discriminatory based on race, gender, color, religious belief, sexual orientation, disability; (f) is otherwise illegal or causes damage or injury to any person or property; or (g) seek to elicit, solicit, collect, detect, use or disclose any information that alone or in combination with other data could identify or locate an individual and that Findmyresearch.com reserves the right, without liability or prejudice to its other rights to you, to disable your access to any material that breaches the provisions of this clause.</p>

                    <p className="text"><strong>5.2</strong>You shall not: (a) except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under this agreement: (i) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of services in any form or media or by any means; (ii) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the services; (b) access all or any part of the services in order to build a product or service which competes with the services; (c) use the services to provide services to third parties; (d) license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the services available to any third party except the Authorised Users.</p>

                    <p className="text"><strong>5.3</strong>You are forbidden from Using the  database for any purpose other than to personally find a research project to work on or to post a research that you need help with. You are explicitly forbidden to: </p>
                    <p className="text spacer"><span className="marker" />Send commercial or unsolicited email or initiate telephone or other forms of communication.</p>
                    <p className="text spacer"><span className="marker" />Communications requesting the employers or subscribers to post information on competing services is expressly prohibited.</p>

                    <p className="text"><strong>5.4</strong>You are forbidden from attempting to transmit unsolicited or commercial emails, faxes, or phone calls, for any purpose other than:</p>
                    <p className="text spacer"><span className="marker" />Communicating with a project owner to find out more about a research project and participate in said project.</p>
                    <p className="text spacer"><span className="marker" />Project owners can then communicate with applicants after they have been reached out to.</p>

                    <p className="subtitle">6. DISCLAIMER </p>
                    <p className="text"><strong>6.1</strong>You agree that when posting your project (as a project owner/poster) you shall be solely responsible and/or liable for all information and/or content you post about a project. This include issues pertaining to credibility and legitimacy. Further to that, as the project owner/poster you are also responsible and/or liable for ensuring that all paperwork, training, and/or authorization that is necessary for both clinical research approval as well as accessing protect health information (PHI) in your project (i.e. IRBs, CITI training, HIPAA training, etc) has been performed by not only yourself but also anyone assisting you with the project, including students/trainees that contact you and choose to help with your project via this platform. The findmyresearch.com team is NOT responsible or liable for ensuring that the appropriate paperwork, training, and/or authorization that is necessary for accessing PHI is performed for any project - this responsibility falls directly on both the project poster and the student/trainee assisting with the project.  Finally, you also agree that by posting your project, you have obtained appropriate permission for your institution to do so, and you verify understanding that findmyreseach.com has no intention of accessing or obtaining any intellectual property of yours or your institution. </p>
                    <p className="text"><strong>6.2</strong>If you are posting a project that is not your own, or on someone else’s behalf, you verify understanding that the entirety of article 6.1 applies to the person whose contact info will be given for trainees/students to contact. You verify that you have obtained permission to post the project from the person who will be the point of the contact for the project (and whose contact info will be written on the website). </p>
                    <p className="text"><strong>6.3</strong>If you are a student/trainee who is accepting to help a project owner/poster with their project, you are yourself responsible and/or liable for ensuring that all paperwork, training, and/or authorization that is necessary for accessing PHI in the project you are assisting with (i.e. IRBs, CITI training, HIPAA training, etc) are performed. Again, the findmyresearch.com team is NOT responsible or liable for ensuring that the appropriate paperwork, training, and/or authorization that is necessary for accessing PHI is performed for any project - this responsibility falls directly on both the project poster and the student/trainee assisting with the project.  </p>
                    <p className="text"><strong>6.4</strong>You are also liable for ensuring there are no HIPAA violations that happen in the course of your communication and no PHI is disclosed on our site. Be advised that Findmyresearch.com does not permit any PHI on its site, nor is PHI permitted outside the platform on any other medium of communication. By posting and/or accepting a project, you hereby assume all responsibility for guaranteeing there is no HIPAA violations and/or PHI disclosures both on and off the site. </p>
                    <p className="text"><strong>6.5</strong>You hereby acknowledge that Findmyresearch.com is not liable for ensuring the point of contact of the project consented to be contacted for purposes of the specific project. This responsibility shall be placed on the poster (if the poster and the point of contact are not the same person).</p>
                    <p className="text"><strong>6.6</strong>You acknowledge that any communications that occur between a trainee/student, and a project owner or point of contact are at the parties own risk. Findmyresearch.com is not responsible for the contents or subjects of any communication between parties on or off the platform. </p>

                    <p className="subtitle">7. INTELLECTUAL PROPERTY RIGHTS</p>
                    <p className="text"><strong>7.1</strong>Findmyresearch.com acknowledges that ownership of your content and ownership of all intellectual property rights in any content and/or materials shall remain vested in you. You hereby grant Findmyresearch.com a non-exclusive license during the term of your use of this service.</p>
                    <p className="text"><strong>7.2</strong>You acknowledge that all intellectual property rights in the Findmyresearch.com intellectual property rights shall be owned and remain the property of and vested in Findmyresearch.com.</p>
                    <p className="text"><strong>7.3</strong>This is merely a platform connecting users searching for clinical research  projects to assist on (i.e. students/trainees), and users who are searching for assistance in completing their research projects (i.e. project owners/posters). Findmyresearch.com has no intention to gain intellectual property rights of any research project / project owner, nor infringe on any academic institution’s intellectual property.</p>
                    <p className="text"><strong>7.4</strong>Members  and website visitors are forbidden from redistributing any information supplied by Findmyresearch.com (other than your own project). This restriction includes information published on Findmyresearch.com web site, or sent in the form emails or SMS phone messages. This includes, but is not limited to, information about: research projects, information about users. The member is charged with the responsibility of protecting the content from being copied or redistributed. This includes, but is not limited to, clearing browser's cache after each use, taking all necessary steps to protect against unauthorized access to the subscriber's computer, ensuring the security of the login password, etc.</p>

                    <p className="subtitle">8. INDEMNITY </p>
                    <p className="text">You shall, at your sole expense, indemnify, defend and hold Findmyresearch.com harmless from and against any loss, cost, damages, liability or expense arising out of or relating to or arising directly or indirectly out of or in connection with: (i) your use of the services, including your reliance on any information or materials obtained through the use of the service; (ii) your breach of this Agreement; or (iii) testing or using third party services. </p>

                    <p className="subtitle">9. PRIVACY</p>
                    <p className="text">Findmyresearch.com does not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. If you object to your information being transferred or used in this way, please do not use our services.</p>

                    <p className="subtitle">10. LIABILITY LIMIT</p>
                    <p className="text"><strong>10.1</strong>In no event will Findmyresearch.com, be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with our site, our services or this agreement (however arising, including negligence).</p>
                    <p className="text"><strong>10.2</strong>You understand that Findmyresearch.com through its team vets all projects as best as we can. However, we shall not be held liable for the accuracy, credibility and legitimacy of the research projects presented on Findmyresearch.com. You are obligated to carry out personal due diligence on all projects you post and all projects that you engage in. In addition to that, despite the fact that we vet all users i.e. students and/or trainees, we are not liable for the credibility of the said users. You are required to carry out personal due diligence on all users you engage on our platform. </p>
                </div>
            </PageTemplate >
            <LegalDisclaimerPage />
            <PrivacyPolicyPage />
            <CookiePolicyPage />
            <CopyRightPolicyPage />
        </>
    )
}

export default TermsAndConditionsPage;