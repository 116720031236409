import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

import User from '../../api/User';

const UserCard = ({ name, email, title, id, ip }: {
    name: string,
    email: string,
    title: string,
    id: string,
    ip: string,
}) => {
    const isAdmin = title == "Admin" ? true : false;
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const [loadingDeleteBtn, setLoadingDeleteBtn] = useState<boolean>(false);

    const toggleAdmin = async () => {
        setLoadingBtn(true);
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        await User.toggleAdmin(authorization, id, name, isAdmin);
        setLoadingBtn(false);
    }

    const deleteUser = async () => {
        setLoadingDeleteBtn(true);
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        await User.Delete(authorization, id, name);
        setLoadingDeleteBtn(false);
    }

    return (
        <tr className="table-content">
            <td className="table-item">{name}</td>
            <td className="table-item">{email}</td>
            <td className="table-item">{title}</td>
            <td className="table-item">{ip}</td>
            <td className="table-btn"></td>
            <td className="table-btn">
                <Button className={`action-btn ${title}`} variant="contained" onClick={toggleAdmin}>
                    {loadingBtn ? <CircularProgress className="loading-icon" /> : isAdmin ? ("Remove Admin") : ("Set Admin")}
                </Button>
                <Button variant='contained' color='error' className={`action-btn`} style={{ marginLeft: '7px'}} onClick={deleteUser}>
                {loadingDeleteBtn ? <CircularProgress className="loading-icon" /> : "Delete"}
                </Button>
            </td>
        </tr>
    )
}

export default UserCard;