import React, { useState, useEffect } from 'react';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Checkbox from '@mui/material/Checkbox';

import CalcDays from '../../utils/CalcDays';
import '../../assets/css/ProjectCard.css';

import Projects from '../../api/Projects';
import { useStore } from '../../store/user';

const ProjectCard = ({ projectTitle, numberOfStudents, maxNumberOfStudents, projectDescription, _id, createdAt, status, isFavorite, acceptedApplicants, speciality, typeOfResearch, school, refetch }: {
    projectTitle: string,
    numberOfStudents: number,
    maxNumberOfStudents: number,
    projectDescription: string,
    _id: string,
    createdAt: Date,
    status: string,
    isFavorite?: boolean,
    acceptedApplicants: number,
    speciality: string,
    typeOfResearch: string,
    school: string,
    refetch?: Function
}) => {
    const user = useStore((state: any) => state.user);
    const setUser = useStore((state: any) => state.setUser);
    const [days, setDays] = useState<number>(0);
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [checkedFavorite, setCheckedFavorite] = useState<boolean>(false);

    useEffect(() => {
        localStorage.getItem("@fmr/userLogged") == "true" ?
            setIsLogged(true) :
            setIsLogged(false);
        if(user) {
            checkFavorited()
        }
    }, [user])

    const cardDays = async () => {
        const numberDays = await CalcDays(createdAt);
        setDays(numberDays);
    };

    useEffect(() => {
        cardDays();
    }, [])

    const checkFavorited = () => {
        if(user.favoritedProjects.includes(_id)) {
            setCheckedFavorite(true)
        }
    }

    const handleFavorite = async () => {
        let isRefetching = false
        const newUser = user
        if(!newUser.favoritedProjects.includes(_id)) {
            newUser.favoritedProjects.push(_id)
            setUser(newUser)
        } else {
            newUser.favoritedProjects= newUser.favoritedProjects.filter((projectId: string) => projectId !== _id)
            setUser(newUser)
            isRefetching = true
        }
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        await Projects.addFavorite(authorization, _id, checkedFavorite);
        setCheckedFavorite(!checkedFavorite);
        if(isRefetching) refetch!()
    }

    return (
        <a href={`details/${_id}`} id="card-item">
            {isLogged ?
                <>
                    <div className="header-card">
                        <h2 className="title-card">{projectTitle}</h2>
                        <div className="header-icons">
                            <span className={`project-status ${status.split(" ").slice(-1)}`}>{status}</span>

                            <Checkbox checked={checkedFavorite} title={checkedFavorite ? "Remove favorite" : "Add to favorites"} onClick={handleFavorite} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />

                        </div>
                    </div>
                    {/* <p className="days-card">{`${days} ${days == 1 ? "day" : "days"}`}</p>*/}
                    <div className="content-card">
                        <p className="content-text"><strong>School:</strong>{" " + school}</p>
                        <p className="content-text"><strong>Type of Research Project:</strong>{" " + typeOfResearch}</p>
                    </div>
                    <div className={`footer-card footer-${maxNumberOfStudents}`}>
                        <div className={`students-bar students-${numberOfStudents}`} />
                        <p className="students-number">{numberOfStudents} of {maxNumberOfStudents} students</p>
                    </div>
                </> :
                <div className="content-visible">
                    <p><strong>Specialty:</strong>{" " + speciality}</p>
                    <p><strong>Type of Research Project:</strong>{" " + typeOfResearch}</p>
                </div>
            }
        </a>
    )
}

export default ProjectCard;