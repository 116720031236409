import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "../assets/css/Global.css";

import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../pages/Home';
import Login from '../pages/Login';
import CreateAccount from '../pages/CreateAccount';
import ProjectList from '../pages/ProjectList';
import ProjectDetails from '../pages/ProjectDetails';
import CreateProject from '../pages/CreateProject';
import EditProject from '../pages/EditProject';
import EditAccount from '../pages/EditAccount';
import FAQPage from '../pages/FAQPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import ResearchResourcesPage from '../pages/ResearchResourcesPage';
import ContactUsPage from '../pages/ContactUsPage';
import AboutUsPage from '../pages/AboutUsPage';
import MyProfile from '../pages/MyProfile';
import AcceptUser from '../pages/AcceptUser';
import AdvisoryBoardPage from '../pages/AdvisoryBoardPage';

function App() {
    return (
        <div id="principal-content">
            <Router>
                <Header></Header>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/reset-password' element={<Login />} />
                    <Route path='/register' element={<CreateAccount />} />
                    <Route path='/projects' element={<ProjectList />} />
                    <Route path='/details/:id' element={<ProjectDetails />} />
                    <Route path='/create-project' element={<CreateProject />} />
                    <Route path='/edit/:id' element={<EditProject />} />
                    <Route path='/edit-user/:id' element={<EditAccount />} />
                    <Route path='/faq' element={<FAQPage />} />
                    <Route path='/terms-and-conditions' element={<TermsAndConditionsPage isModal={false}/>} />
                    <Route path='/research-resources' element={<ResearchResourcesPage />} />
                    {/* <Route path='/advisory-board' element={<AdvisoryBoardPage />} /> */}
                    <Route path='/contact-us' element={<ContactUsPage />} />
                    <Route path='/about-us' element={<AboutUsPage />} />
                    <Route path='/my-profile' element={<MyProfile />} />
                    <Route path='/accept-user' element={<AcceptUser />} />
                </Routes>
                <Footer></Footer>
            </Router>
        </div>
    );
}

export default App;
