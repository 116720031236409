import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Checkbox from '@mui/material/Checkbox';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2'

import CalcDays from '../utils/CalcDays';
import PageHeader from '../components/PageHeader';
import CardApplicant from '../components/ProjectList/CardApplicant';
import NoProjectsFound from '../components/ProjectList/NoProjectsFound';
import '../assets/css/ProjectDetails.css';
import IProjectItem from '../interfaces/IProjectItem';
import IApplicantItem from '../interfaces/IApplicantItem';
import IApplicants from '../interfaces/IApplicants';
import IUser from '../interfaces/IUser';
import Projects from '../api/Projects';
import User from '../api/User';

const ProjectDetails = () => {
    const params = useParams();
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [modalContactActive, setModalContactActive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingContact, setLoadingContact] = useState<boolean>(false);
    const [loadingApplicants, setLoadingApplicants] = useState<boolean>(true);
    const [isLogged, setIsLogged] = useState<boolean>(true);
    const [showingApplicants, setShowingApplicants] = useState<string>("New");
    const [data, setData] = useState<Partial<IProjectItem>>({});
    const [dataApplicants, setDataApplicants] = useState<Partial<IApplicants>>({});
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("Message");
    const [numberApplicants, setNumberApplicants] = useState<number>()
    const [checkedFavorite, setCheckedFavorite] = useState<boolean>(false);
    const [days, setDays] = useState<number>(0);
    const [repostEnabled, setRepostEnabled] = useState<boolean>(false);
    const [user, setUser] = useState<Partial<IUser>>({});
    const [isRequested, setIsRequested] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem("@fmr/userLogged") == "true") {
            setIsLogged(true);
        } else {
            setIsLogged(false);
            window.location.href = "/login";
        }
    }, [])

    useEffect(() => {
        getUser();
    }, [loading])

    useEffect(() => {
        fetchProject();
    }, [])

    const getUser = async () => {
        const user = JSON.parse(localStorage.getItem("@fmr/user")!);
        const userData = await User.findOne(user.id);
        if (user) {
            setUser(userData);
            if (user.id == data?.owner) {
                setIsOwner(true);
                findApplicants();
            } else {
                setIsOwner(false);
            }
        }
    }

    useEffect(() => {
        data?.applicants?.map((requestedApplicant: any) => {
            if (requestedApplicant._id == user.id) {
                setIsRequested(true);
            }
        });

        data?.declinedApplicants?.map((requestedApplicant: any) => {
            if (requestedApplicant == user.id) {
                setIsRequested(true);
            }
        });

        data?.acceptedApplicants?.map((requestedApplicant: any) => {
            if (requestedApplicant._id == user.id) {
                setIsRequested(true);
            }
        });
    }, [data?.applicants])

    const fetchProject = async () => {
        const projectId = params.id;
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const projectData = await Projects.findOne(projectId!, authorization, setData);
        const filterFavorites = { page: 1, limit: 999 };
        const favoritesItems = await Projects.findFavorites(setLoading, authorization, filterFavorites);
        const numberDays = await CalcDays(projectData.dayUntilDeactivation);
        //if (numberDays >= 30) setRepostEnabled(true);
        setDays(numberDays);

        favoritesItems.favoritedProjects.map((favorite: any) => {
            if (favorite._id === projectData._id) {
                setCheckedFavorite(true);
            }
        });
        setLoading(false);
    };

    const findApplicants = async () => {
        setLoadingApplicants(true);
        setShowingApplicants("New");
        const projectId = params.id;
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const applicantsItems = await Projects.findApplicants(projectId!, authorization);
        applicantsItems.applicants.length == 0 ? setDataApplicants({ applicants: undefined }) : setDataApplicants({ applicants: applicantsItems.applicants });
        setNumberApplicants(applicantsItems.applicants.length);
        setLoadingApplicants(false);
    };

    const findAccepted = async () => {
        setLoadingApplicants(true);
        setShowingApplicants("Accepted");
        const projectId = params.id;
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const applicantsItems = await Projects.findAccepted(projectId!, authorization);
        applicantsItems.acceptedApplicants.length == 0 ? setDataApplicants({ applicants: undefined }) : setDataApplicants({ applicants: applicantsItems.acceptedApplicants });
        setNumberApplicants(applicantsItems.acceptedApplicants.length);
        setLoadingApplicants(false);
    };

    const findDenied = async () => {
        setLoadingApplicants(true);
        setShowingApplicants("Denied");
        const projectId = params.id;
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const applicantsItems = await Projects.findDenied(projectId!, authorization);
        applicantsItems.declinedApplicants.length == 0 ? setDataApplicants({ applicants: undefined }) : setDataApplicants({ applicants: applicantsItems.declinedApplicants });
        setNumberApplicants(applicantsItems.declinedApplicants.length);
        setLoadingApplicants(false);
    };

    const itemsApplicants = () => {
        if (dataApplicants?.applicants == undefined) {
            return <NoProjectsFound message="No applicants found" />
        } else {
            return dataApplicants?.applicants?.map((applicant: IApplicantItem) => {
                return <CardApplicant
                    showingApplicants={showingApplicants}
                    firstName={applicant.user.firstName}
                    lastName={applicant.user.lastName}
                    email={applicant.user.email}
                    title={applicant.user.title}
                    _id={applicant.user._id}
                    numberOfAcceptances={applicant.user.numberOfAcceptances}
                    institution={applicant.user.institution}
                    projectId={params?.id}
                />;
            })
        }
    }

    const handleContactModal = () => {
        setModalActive(false);
        setModalContactActive(true);
    }

    const handleContact = (e: React.FormEvent) => {
        e.preventDefault();
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        Projects.applyToAProject(authorization, data?._id!, message, setLoadingContact, user?.numberOfRequests! + 1, setIsRequested);
    }

    const deleteProject = async () => {
        const projectId = params.id;
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        await Projects.deleteProject(authorization, projectId!);
    }

    const handleFavorite = async () => {
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        await Projects.addFavorite(authorization, data?._id!, checkedFavorite);
        setCheckedFavorite(!checkedFavorite);
    }

    const repostProject = async () => {
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        await Projects.reactivate(authorization, data?._id);
    }

    const handleContactInfo = () => {
        user?.numberOfRequests! <= 7 ?
            (user?.isEmailConfirmed ?
                setModalActive(!modalActive) :
                (Swal.fire({
                    title: 'Error',
                    text: 'You need to confirm your email before contacting the project owner',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }))
            ) :
            (Swal.fire({
                title: 'Error',
                text: 'You have exceeded the weekly request limit (7)',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            }))
    }

    const alertUser = () => {
        Swal.fire({
            text: 'Only students can contact researchers',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
    }

    return (
        <div id="project-details">
            <PageHeader title="Project details" />
            {loading ?
                <CircularProgress className="loading-details" />
                :
                <div className="content-details">
                    <div className="card-project">
                        <div className="header-card">
                            <h2 className="title-card">{data?.projectTitle}</h2>
                            <div className="header-icons">
                                <span className={`project-status ${data?.status?.split(" ").slice(-1)}`}>{data?.status}</span>
                                <Checkbox checked={checkedFavorite} onClick={handleFavorite} title="Add to favorites" icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                            </div>
                            <div className="header-infos">
                                <p className="card-speciality">{data?.speciality}</p>
                                {data?.status == "Pending Completion" && isOwner &&
                                    <p className="card-days"><strong>Days until deactivation: </strong>{days}</p>
                                }
                            </div>
                        </div>
                        <p className="card-description">
                            {data?.projectDescription}
                        </p>
                        <div className="card-footer">
                            <LocationOnIcon className="icon-location" />
                            <p>{data?.school}</p>
                        </div>
                    </div>
                    <div className="contact-cards">
                        <div className={`card-contact footer-${data?.maxNumberOfStudents}`}>
                            <div className={`students-bar students-${data?.numberOfStudents}`} />
                            <p className="students-text">{data?.numberOfStudents} of {data?.maxNumberOfStudents} students</p>
                            <div className="training-text">
                                <p className="training-ask">Will project require PHI access?</p>
                                <p className="training-answer">{data?.trainingRequired}</p>
                            </div>
                            {isOwner ?
                                <div className="owner-actions">
                                    <Button className="owner-btn delete-btn" onClick={deleteProject}>Delete</Button>
                                    {/*<Button className="owner-btn" variant="outlined" onClick={repostProject}>Repost</Button>*/}
                                    <Button className="owner-btn edit-btn" variant="contained"><a href={`/edit/${data?._id}`}>Edit</a></Button>
                                </div>
                                :
                                (
                                    user.title == "Student/Trainee" ?
                                        (
                                            !isRequested &&
                                            <Button onClick={handleContactInfo} className="btn-contact" variant="contained">Contact project owner</Button>
                                        )
                                        :
                                        <Button onClick={alertUser} className="btn-contact" variant="contained">Contact project owner</Button>
                                )
                            }
                        </div>
                        {isOwner &&
                            <div className="card-contact card-owner">
                                <p className="number-requests-title">Number of {showingApplicants} requests to the project:</p>
                                <p className="number-requests">{numberApplicants!}</p>
                                <div className="owner-actions">
                                    <Button className="owner-btn" variant="contained" onClick={findApplicants}>New</Button>
                                    <Button className="owner-btn" variant="outlined" onClick={findAccepted}>Accepted</Button>
                                    <Button className="owner-btn delete-btn" onClick={findDenied}>Denied</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className={modalActive ? "content-modal" : "content-modal modal-inactive"}>
                <div onClick={() => setModalActive(!modalActive)} className="background-modal" />
                <div className="box-modal">
                    <h2 className="title-modal">Terms and Conditions</h2>
                    <div className="text-modal">
                        I have read the terms and conditions, and agree with the contents. I understand It is forbidden to disclose any protected health information on this website or in any communication off the site. I agree that Findmyresearch.com is not liable or responsible for ensuring that I have completed any legally required PHI / HIPAA / CITI training/authorization. I understand the projects have been vetted to the best of the Findmyresearch.com team’s ability but they are not responsible for accuracy or legitimacy. I agree not to attempt to obtain any intellectual property. Please read terms and conditions (particularly terms and conditions section 6 and 7) for details.

                    </div>
                    <div className="footer-modal">
                        <Button onClick={() => setModalActive(!modalActive)} className="modal-btn" variant="outlined">decline</Button>
                        <Button onClick={handleContact} className="modal-btn" variant="contained">accept</Button>
                    </div>
                </div >
            </div >

            {/* <div className={modalContactActive ? "content-modal modal-contact" : "content-modal modal-inactive"}>
                <div onClick={() => setModalContactActive(!modalContactActive)} className="background-modal" />
                <form className="box-modal" onSubmit={handleContact}>
                    <h2 className="title-modal">Contact Project Owner</h2>
                    <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        multiline
                        rows={8}
                        placeholder="Send a message to the project owner"
                        value={message || ''}
                        onChange={e => setMessage(e.target.value)}
                    />
                    <Button type="submit" className="btn-contact" variant="contained">{loadingContact ? <CircularProgress className="loading-icon-btn" /> : "Send"}</Button>
                </form>
        </div>*/}
            {isOwner &&
                <div className="applicants-list">
                    <h2 className="applicants-title">{showingApplicants} project requests</h2>
                    <div className="content-applicants">
                        {loadingApplicants ? <CircularProgress className="loading-details" /> : itemsApplicants()
                        }
                    </div>
                </div>
            }
        </div >
    )
}

export default ProjectDetails; 