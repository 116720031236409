import React, { useState, useEffect } from 'react';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Checkbox from '@mui/material/Checkbox';

import Projects from '../../../api/Projects';
import CalcDays from '../../../utils/CalcDays';
import "../../../assets/css/HomeCard.css";

const HomeCard = ({ projectTitle, numberOfStudents, maxNumberOfStudents, projectDescription, _id, createdAt, acceptedApplicants, speciality, typeOfResearch, school, isFavorite }: {
    projectTitle: string,
    numberOfStudents: number,
    maxNumberOfStudents: number,
    projectDescription: string,
    _id: string,
    createdAt: Date,
    acceptedApplicants: number,
    speciality: string,
    typeOfResearch: string,
    school: string,
    isFavorite: boolean
}) => {
    const [days, setDays] = useState<number>(0);
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [checkedFavorite, setCheckedFavorite] = useState<boolean>(isFavorite);

    const cardDays = async () => {
        const numberDays = await CalcDays(createdAt);
        setDays(numberDays);
    };

    const handleFavorite = async () => {
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        await Projects.addFavorite(authorization, _id, checkedFavorite);
        setCheckedFavorite(!checkedFavorite);
    }

    useEffect(() => {
        cardDays();

        localStorage.getItem("@fmr/userLogged") == "true" ?
            setIsLogged(true) :
            setIsLogged(false);
    }, [])

    return (
        <a href={`details/${_id}`} id={isLogged ? "project-card" : "card-visible"}>
            {isLogged ?
                <div className="content-card">
                    <div className="card-header">
                        <p className="title-card">{projectTitle}</p>
                        <Checkbox className="favorite-icon" checked={checkedFavorite} title={checkedFavorite ? "Remove favorite" : "Add to favorites"} onClick={handleFavorite} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                        {/*<p className="period-card">{`${days} ${days > 1 ? "days" : "day"}`}</p>*/}
                    </div>
                    <div className="card-resume">
                        <p className="content-text"><strong>School:</strong>{" " + school}</p>
                        <p className="content-text"><strong>Type of Research Project:</strong>{" " + typeOfResearch}</p>
                    </div>
                    <div className={`students-number footer-${maxNumberOfStudents}`}>
                        <p className="students-quantity">{numberOfStudents} of {maxNumberOfStudents} students</p>
                        <div className={`progress-bar students-${numberOfStudents}`}></div>
                    </div>
                </div>
                :
                <div className="content-visible">
                    <p><strong>Specialty:</strong>{" " + speciality}</p>
                    <p><strong>Type of Research Project:</strong>{" " + typeOfResearch}</p>
                </div>
            }
        </a>
    )
}

export default HomeCard;