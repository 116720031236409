import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';

import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import User from "../api/User";
import IUser from '../interfaces/IUser';
import "../assets/css/CreateAccount.css";

const CreateAccount = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [isBoxChecked, setIsBoxChecked] = useState<boolean>(false);
    const [data, setData] = useState<Partial<IUser>>({
        title: undefined
    });
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const [userIp, setUserIp] = useState<string>("");

    useEffect(() => {
        getIp();
    }, [])

    const declineTermsCondition = () => {
        setModalActive(false);
        setIsBoxChecked(false);
    }

    const acceptTermsCondition = () => {
        setModalActive(false);
        setIsBoxChecked(true);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        confirmPassword != password ? setErrorPassword(true) : setErrorPassword(false);
        User.register(data, password, setLoading);
    }

    const getIp = async () => {
        const userIp = await User.getIp();
        setData({
            ...data,
            ip: userIp
        })
    }

    return (
        <div id="create-account">
            <form className="content-form" onSubmit={e => handleSubmit(e)} >
                <h1 className="title-form">Create account</h1>
                <div className="form-item">
                    <TextField
                        required
                        id="outlined"
                        label="First name"
                        value={data.firstName || ''}
                        onChange={e => setData({
                            ...data,
                            firstName: e.target.value
                        })}
                    />
                    <TextField
                        required
                        id="outlined"
                        label="Last name"
                        value={data.lastName || ''}
                        onChange={e => setData({
                            ...data,
                            lastName: e.target.value
                        })}
                    />
                </div>
                <FormControl fullWidth>
                    <InputLabel>Title</InputLabel>
                    <Select
                        required
                        label="Title"
                        value={data.title || ''}
                        onChange={e => setData({
                            ...data,
                            title: e.target.value
                        })}
                    >
                        <MenuItem value="Researcher/Project Poster">Researcher/Project Poster</MenuItem>
                        <MenuItem value="Student/Trainee">Student/Trainee</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    required
                    type="email"
                    id="outlined"
                    label="Email address"
                    value={data.email || ''}
                    onChange={e => setData({
                        ...data,
                        email: e.target.value
                    })}
                />
                <TextField
                    required
                    type="text"
                    id="outlined"
                    label="Institution"
                    value={data.institution || ''}
                    onChange={e => setData({
                        ...data,
                        institution: e.target.value
                    })}
                />
                <TextField
                    required
                    id="outlined-password-input"
                    label="Password (8+ characters)"
                    type="password"
                    value={password || ''}
                    onChange={e => setPassword(e.target.value)}
                />
                <TextField
                    required
                    error={errorPassword}
                    id="outlined-password-input"
                    label="Confirm password"
                    type="password"
                    helperText={errorPassword ? "Passwords must match" : ""}
                    onChange={e => setConfirmPassword(e.target.value)}
                />
                <div className="box-terms" >
                    <Checkbox onClick={() => setIsBoxChecked(!isBoxChecked)} checked={isBoxChecked} />
                    <p onClick={() => setModalActive(!modalActive)} className="text-terms">I accept the Terms and Conditions</p>
                </div>
                <Button disabled={!isBoxChecked} type="submit" className="form-btn" variant="contained">
                    {!loading ? 'Register' : <CircularProgress color="inherit" />}
                </Button>
            </form>
            <div className={modalActive ? "content-modal" : "content-modal modal-inactive"}>
                <div onClick={() => setModalActive(!modalActive)} className="background-modal" />
                <div className="box-modal">
                    <h2 className="title-modal">Terms and Conditions</h2>
                    <div className="text-modal">
                        <TermsAndConditionsPage isModal={true} />
                    </div>
                    <div className="footer-modal">
                        <Button onClick={declineTermsCondition} className="modal-btn" variant="outlined">decline</Button>
                        <Button onClick={acceptTermsCondition} className="modal-btn" variant="contained">accept</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount;