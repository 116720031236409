import React from 'react';

import '../assets/css/PageHeader.css';

const PageHeader = ({title}: any) => {
    return (
        <div id="page-header">
            <h1 className="title">
                {title}
            </h1>
        </div>
    )
}

export default PageHeader;