import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Logo from '../assets/images/header-logo.svg';
import '../assets/css/Footer.css';
import ISponsor from '../interfaces/ISponsor';
import Sponsor from '../api/Sponsor';

const Footer = () => {
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [SponsorList, setSponsors] = useState<ISponsor[] | undefined>(undefined)
  
    const fetchSponsors = async () => {
      const sponsors = await Sponsor.findAll(setLoading)
      if(sponsors !== null) {
        setSponsors(sponsors)
      }
      console.log(sponsors)
    }
  
    useEffect(() => {
      fetchSponsors()
    }, [])


    useEffect(() => {
        localStorage.getItem("@fmr/userLogged") == "true" ?
            setIsLogged(true) :
            setIsLogged(false);
    }, [])

    if (!SponsorList) return <></>

    return (
        <div id="footer">
            <div className="content-footer">
                <div className="first-content">
                    <div className="logo-footer">
                        <img src={Logo} alt="Logo Footer" />
                        {SponsorList[1].activated && <>
                            {SponsorList[1].hyperlink ? 
                            <a href={SponsorList[1].link}>
                                {SponsorList[1].text}
                            </a> : 
                            <p>
                                {SponsorList[1].text}
                            </p>}
                        </>}
                    </div>
                    <p className="copyright">Designed by CritiColl</p>
                </div>
                <div className="second-content">
                    <div className="column-footer">
                        <p className="title">Useful links</p>
                        <a href="/research-resources" className="link-footer">Research Resources</a>
                        <a href="/faq" className="link-footer" title="FAQ">FAQ</a>
                        <a href="/contact-us" className="link-footer">Contact us</a>
                        <a href="/about-us" className="link-footer">About us</a>
                        <a href="/terms-and-conditions" className="link-footer">Terms and Conditions</a>
                    </div>
                    <div className="column-footer">
                        <p className="title">Navigation pages</p>
                        {isLogged ?
                            <a href="/my-profile" className="link-footer" title="My Profile">My Profile</a> :
                            <a href="/login" className="link-footer">Login</a>
                        }
                        <a href="/projects" className="link-footer">Search Projects</a>
                        <a href="/create-project" className="link-footer">Post a Project</a>
                        {SponsorList[2].activated && <div className='footer-sponsor'>
                            {SponsorList[2].hyperlink ? 
                            <a href={SponsorList[2].link} className="link-footer">
                                {SponsorList[2].text}
                            </a> : 
                            <p className="link-footer">
                                {SponsorList[2].text}
                            </p>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;