import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { CircularProgress } from '@mui/material';

import User from "../api/User";
import IUser from '../interfaces/IUser';
import "../assets/css/Login.css";

const Login = () => {
    const [data, setData] = useState<Partial<IUser>>({});
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [forgotData, setForgotData] = useState<Partial<IUser>>({});
    const [reset, setisReset] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errorPasswordReset, setErrorPasswordReset] = useState<boolean>(false);
    const [token, setToken] = useState<string>("");

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const urlToken = urlParams.get('confirm');
        await User.login(data, setLoading, setErrorPassword, urlToken);
    }

    const forgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        await User.forgotPassword(forgotData, setModalActive);
    }

    useEffect(() => {
        if (window.location.pathname.includes("reset-password")) {
            setModalActive(true);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlToken = urlParams.get('token')
            setToken(urlToken!);
            setisReset(true);
        }
    }, [])

    const handleResetPassword = (e: React.FormEvent) => {
        e.preventDefault();
        if (confirmPassword != password) {
            setErrorPasswordReset(true)
        } else {
            setErrorPasswordReset(false);
            const dataReset = { token: token }
            User.resetPassword(dataReset, password);
        }
    }

    return (
        <div id="login">
            <form className="content-form" onSubmit={e => handleLogin(e)}>
                <h1 className="title-form">Log in</h1>
                <TextField
                    required
                    id="outlined"
                    label="Email address"
                    className="email-field"
                    value={data.email || ''}
                    onChange={e => setData({
                        ...data,
                        email: e.target.value
                    })}
                />
                <TextField
                    required
                    id="outlined-password-input"
                    label="Password"
                    type="password"
                    error={errorPassword}
                    helperText={errorPassword ? "Incorrect password." : ""}
                    value={data.password || ''}
                    onChange={e => setData({
                        ...data,
                        password: e.target.value
                    })}
                />
                <Button type="submit" className="form-btn login-btn" variant="contained">
                    {!loading ? 'Log in' : <CircularProgress color="inherit" />}
                </Button>
                <p className="divider-text">or</p>
                <Button className="form-btn register-btn" variant="outlined"><a href="/register">Create Account</a></Button>
                <span className="forgot-text" onClick={() => setModalActive(!modalActive)}>Forgot password?</span>
            </form>
            <div className={modalActive ? "content-modal" : "content-modal modal-inactive"}>
                <div onClick={() => setModalActive(!modalActive)} className="background-modal" />
                <form className="box-modal forgot-modal" onSubmit={e => (reset ? handleResetPassword(e) : forgotPassword(e))}>
                    <h2 className="title-modal">{reset ? "Enter new password" : "Forgot password"}</h2>
                    {!reset && <p className="forgot-subtitle">Please, enter your email address. You will receive a link to create a new password via email.</p>}
                    {!reset ? <TextField
                        required
                        id="outlined"
                        label="Email address"
                        className="email-field"
                        value={forgotData.email || ''}
                        onChange={e => setForgotData({
                            ...forgotData,
                            email: e.target.value
                        })}
                    />
                        :
                        <div className="reset-fields">
                            <TextField
                                required
                                id="outlined-password-input"
                                label="Password (8+ characters)"
                                type="password"
                                value={password || ''}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <TextField
                                required
                                error={errorPasswordReset}
                                id="outlined-password-input"
                                label="Confirm password"
                                type="password"
                                helperText={errorPasswordReset ? "Passwords must match" : ""}
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    }
                    <div className="footer-modal">
                        <Button className="modal-btn" variant="contained" type="submit">Send</Button>
                    </div>
                </form>
            </div>
        </div >
    )
}

export default Login;