import { TextField,  ButtonGroup,  Button, Checkbox, Chip, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import Sponsor from "../../api/Sponsor";
import ISponsor from "../../interfaces/ISponsor";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getRichText, updateRichText } from "../../api/RichText";


const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

const Website = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sponsors, setSponsors] = useState<ISponsor[] | undefined>(undefined)
  const [richText, setRichText] = useState('');

  const fetchSponsors = async () => {
    const sponsors = await Sponsor.findAll(setLoading)
    if(sponsors !== null) {
      setSponsors(sponsors)
    }
    console.log(sponsors)
  }

  const fetchRichText = async () => {
    const richText = await getRichText()
    if(richText !== null) {
      setRichText(richText.richText.content)
    }
    console.log(richText)
  }

  const handleClick = async () => {
    const response = await updateRichText(richText)
    console.log(response)
  }


  useEffect(() => {
    fetchSponsors()
    fetchRichText()
  }, [])

  if(loading || !sponsors) return <></>

  return <>
    <div className="content-admin">
      <h1 className="admin-title">Website configuration</h1>
      <form>
        <FormItem placement="Header Logo Text" 
        id={sponsors[0]._id} 
        currenText={sponsors[0].text} 
        secondaryText={sponsors[0].secondaryText} 
        activated={sponsors[0].activated} 
        hyperlink={sponsors[0].hyperlink} 
        link={sponsors[0].link} 
        secondaryLink={sponsors[0].secondaryLink} 
        secondLine={sponsors[0].secondLine} 
        showSecondLineInput={true} />
        <FormItem placement="Foorter Logo Text" id={sponsors[1]._id} currenText={sponsors[1].text} activated={sponsors[1].activated} hyperlink={sponsors[1].hyperlink} link={sponsors[1].link} secondLine={sponsors[1].secondLine}/>
        <FormItem placement="Footer Links Text" id={sponsors[2]._id} currenText={sponsors[2].text} activated={sponsors[2].activated} hyperlink={sponsors[2].hyperlink} link={sponsors[2].link} secondLine={sponsors[2].secondLine}/>
      </form>

      {/* <div>
        <h1 className="admin-title">Advisory Board</h1>
        <ReactQuill theme="snow" formats={formats} modules={modules} tabIndex={200} value={richText} onChange={setRichText} />
        <div className="quill-confirm-button">
          <Button variant="contained" color="success" onClick={ () => handleClick()}>Confirm</Button>
        </div>
      </div> */}
    </div>
  </>
}

type FormItemParams = {
  id: string
  placement: string
  currenText: string
  secondaryText?: string
  activated: boolean
  hyperlink: boolean
  link: string
  secondaryLink?: string
  secondLine: boolean
  showSecondLineInput?: boolean
}

const FormItem = ({id, placement, currenText, secondaryText, activated, hyperlink, link, secondaryLink, secondLine, showSecondLineInput}: FormItemParams) => {
  const [checked, setChecked] = useState<boolean>(hyperlink)
  const [secondLineChecked, setSecondLineChecked] = useState<boolean>(secondLine)
  const [sponsorData, setSponsorData] = useState<ISponsor>({
    _id: id,
    activated,
    text: currenText,
    secondaryText,
    hyperlink,
    link,
    secondaryLink,
    secondLine
  })
  const [text, setText] = useState<string>()
  const [secondaryTextInput, setSecondaryTextInput] = useState<string>()
  const [hyperlinkText, setHyperlinkText] = useState<string>()
  const [secondaryHyperlinkText, setSecondaryHyperlinkText] = useState<string>()

  const chipLabel = sponsorData.activated ? "ACTIVE" : "UNACTIVE"
  const chipColor = sponsorData.activated ? "success" : "default"

  const handleCheck = async () => {
    const sponsor = await Sponsor.toggleHyperLink(sponsorData)
    if (sponsor) {
      setSponsorData({
        ...sponsor,
      })
      setChecked(!checked)
    }
  }

  const handleTextCheck = async () => {
    const sponsor = await Sponsor.toggleSecondLineText(sponsorData)
    if (sponsor) {
      setSponsorData({
        ...sponsor,
      })
      setSecondLineChecked(!secondLineChecked)
    }
  }

  const handleActivation = async (activation: boolean) => {
    const sponsor = await Sponsor.toggleActivation(sponsorData._id, activation)
    if (sponsor) {
      setSponsorData({
        ...sponsor
      })
    }
  }

  const handleTextSubmit = async (text: string, hyperlink?: boolean) => {
    const sponsor = await Sponsor.changeText(sponsorData._id, text, hyperlink)
    if (sponsor) {
      setSponsorData({
        ...sponsor
      })
    }
  }

  const handleSecondaryTextSubmit = async (text: string, hyperlink?: boolean) => {
    const sponsor = await Sponsor.changeSecondaryText(sponsorData._id, text, hyperlink)
    if (sponsor) {
      setSponsorData({
        ...sponsor
      })
    }
  }

  return (
    <>
      <div className="form-header">
        <div>
        <p><span>{sponsorData.text}</span></p>
        <Divider orientation="vertical" flexItem  /> 
        <p><span>HYPERLINK: </span> <Checkbox checked={checked} onChange={handleCheck}  /></p>
        {showSecondLineInput && (
          <>
          <p><span>Show Second Line: </span> <Checkbox checked={secondLineChecked} onChange={handleTextCheck}  /></p>
          </>
        )}
        </div>
        <Chip label={chipLabel} color={chipColor}/>
      </div>
      <div className="form-item">
        <div className="form-item_text">
          <TextField label={placement} fullWidth onChange={ (e) => setText(e.target.value)} inputProps={{ maxLength: 35 }} />
        </div>
          <ButtonGroup variant="contained" disableElevation>
            <Button variant="contained" onClick={() => handleTextSubmit(text!)}>
              Change
            </Button>
            <Button variant="outlined" disabled={sponsorData.activated} onClick={() => handleActivation(true)}>
              Activate
            </Button>
          </ButtonGroup>
            <Button variant="outlined" color="error" disabled={!sponsorData.activated} onClick={() => handleActivation(false)}>
              Deactivate
            </Button>
      </div>
      {sponsorData.secondLine && (
        <>
          <div className="form-item">
            <div className="form-item_text">
              <TextField label="Secondary Logo Text" fullWidth onChange={ (e) => setSecondaryTextInput(e.target.value)} inputProps={{ maxLength: 35 }}/>
            </div>
              <Button variant="contained" onClick={() => handleSecondaryTextSubmit(secondaryTextInput!)}>
                Change
              </Button>
          </div>
        </>
      )}
      {sponsorData.hyperlink && 
      <>
        <div className="url">
          <a href={sponsorData.link}>{sponsorData.link}</a> 
          <div>
            <TextField label="URL" size="small" onChange={ (e) => setHyperlinkText(e.target.value)}/>
            <Button variant="contained" disableElevation onClick={() => handleTextSubmit(hyperlinkText!, true)}>Change</Button>
          </div>
        </div>
        {sponsorData.secondLine &&
        <div className="url">
          <a href={sponsorData.secondaryLink}>{sponsorData.secondaryLink}</a> 
          <div>
            <TextField label="Secondary URL" size="small" onChange={ (e) => setSecondaryHyperlinkText(e.target.value)}/>
            <Button variant="contained" disableElevation onClick={() => handleSecondaryTextSubmit(secondaryHyperlinkText!, true)}>Change</Button>
          </div>
        </div>}
      </>
      }
    </>
  )
}
export default Website;