import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '../assets/images/header-logo.svg';
import '../assets/css/Header.css';
import ISponsor from '../interfaces/ISponsor';
import Sponsor from '../api/Sponsor';

const Header = () => {
    const [mobileOpen, setMobileOpen] = useState<boolean>(true);
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState<boolean>(true);
    const [SponsorList, setSponsors] = useState<ISponsor[] | undefined>(undefined)
  
    const fetchSponsors = async () => {
      const sponsors = await Sponsor.findAll(setLoading)
      if(sponsors !== null) {
        setSponsors(sponsors)
      }
      console.log(sponsors)
    }
  
    useEffect(() => {
      fetchSponsors()
    }, [])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        localStorage.getItem("@fmr/userLogged") == "true" ?
            setIsLogged(true) :
            setIsLogged(false);
    }, [])

    const handleMenu = () => {
        setMobileOpen(!mobileOpen);
    }

    useEffect(() => {
        checkLoginDate();
    }, [])

    const checkLoginDate = () => {
        const loginDateRaw = localStorage.getItem("@fmr/userLoginDate")!;
        const loginDate = new Date(loginDateRaw);
        const now = new Date();
        var diff = Math.abs(loginDate.getTime() - now.getTime()) / 3600000;
        if (diff > 24) {
            localStorage.removeItem('@fmr/userLogged');
            setIsLogged(false);
        } else {
            setIsLogged(true);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("@fmr/userLogged");
        localStorage.removeItem("@fmr/user");
        localStorage.removeItem("@fmr/userAuthorization");
        localStorage.removeItem("@fmr/userLoginDate");
        window.location.href = "/";
    }

    if (!SponsorList) return <></>

    return (
        <div id="header" >
            <div className="content-header">
                {isMobile &&
                    <MenuIcon
                        className="menu-mobile"
                        onClick={handleMenu}
                    />
                }
                <div className="box-logo">
                    <a href="/" >
                        <img src={Logo} alt="Logo" />
                    </a>
                    {SponsorList[0].activated && 
                        <>
                            {SponsorList[0].hyperlink ? 
                            <>
                                <a href={SponsorList[0].link} className="spornsor-text">
                                    {SponsorList[0].text}
                                </a>
                                {SponsorList[0].secondLine &&
                                    <a href={SponsorList[0].secondaryLink} className="spornsor-text">
                                        {SponsorList[0].secondaryText}
                                    </a>
                                } 
                            </>
                            : 
                            <>
                                <p className="spornsor-text">
                                    {SponsorList[0].text}
                                </p>
                                {SponsorList[0].secondLine &&
                                    <p className="spornsor-text">
                                        {SponsorList[0].secondaryText}
                                    </p>
                                }
                            </>
                            
                            }
                        </>}
                </div>
                {!isMobile &&
                    <nav className="items-header">
                        <div className="search-input">
                            <Button href="/projects" className="header-search" variant="outlined">
                                <SearchIcon className="search-icon" />View all projects
                            </Button>
                        </div>
                        {/* <a href="/advisory-board" className="link-header" title="Advisory Board">Advisory Board</a> */}
                        <a href="/research-resources" className="link-header" title="Research Resources">Research Resources</a>
                        <a href="/faq" className="link-header" title="FAQ">FAQ</a>
                        {isLogged ?
                            <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="link-header"
                                >
                                    My Profile
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <a className="link-menu" href="/my-profile"><MenuItem onClick={handleClose}>My Profile</MenuItem></a>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </div> :
                            <a href="/login" className="link-header" title="Login">Log In</a>
                        }
                        <Button className="register-header" variant="outlined"> <a href="/create-project">post a project</a></Button>
                    </nav>
                }
            </div>
            {
                isMobile && <div className={mobileOpen ? 'items-mobile' : 'items-mobile menu-open'}>
                    <CloseIcon className="close-mobile" onClick={handleMenu} />
                    <nav className="items-header">
                        <div className="search-input">
                            <Button href="/projects" className="header-search" variant="outlined">
                                <SearchIcon className="search-icon" />View all projects
                            </Button>
                        </div>
                        <a href="/research-resources" className="link-header" title="Research Resources">Research Resources</a>
                        <a href="/faq" className="link-header" title="FAQ">FAQ</a>
                        {isLogged ?
                            <a href="/my-profile" className="link-header" title="My Profile">My Profile</a> :
                            <a href="/login" className="link-header" title="Login">Log In</a>
                        }
                        <Button href="/create-project" className="register-header" variant="outlined">post a project</Button>
                    </nav>
                </div>
            }
        </div >
    )
}

export default Header;