import create from 'zustand';
import User from '../api/User';
import IUser from '../interfaces/IUser';

export const useStore = create((set) => ({
  user: JSON.parse(localStorage.getItem('@fmr/user')!),
  setUser: (user: IUser) =>
    set((state: any) => ({
      ...state,
      user,
    })),
  fetchUser: async (user: IUser) => {
    const fetchedUser = await User.findOne(user.id);
    set({ user: fetchedUser });
  },
  adminUser: JSON.parse(localStorage.getItem('@fmr/admin')!),
}));