import React from 'react';

import PageTemplate from '../components/PageTemplate';

const CopyRightPolicyPage = () => {
    return (
        <PageTemplate title="COPYRIGHT POLICY ">
            <div id="privacy-content">
                <p className="subtitle">1. ACCEPTING THESE TERMS </p>
                <p className="text">This document, our rules, policies and the other documents referenced make up our copyrights. The copyright terms are a legally binding contract between you and us. Please read them carefully.</p>

                <p className="subtitle">2. CHANGES </p>
                <p className="text">Findmyresearch.com may amend the copyright terms at any time by posting a revised version. The revised version will be effective at the time we post it. </p>

                <p className="subtitle">3. COPY RIGHT </p>
                <p className="text">You acknowledge that the site contains information, data, photographs, graphics and other material (collectively “Content”) that are protected by copyrights, trademarks, trade secrets, rights in databases and/or other proprietary rights, and that these rights are valid and protected in all forms, media and technologies existing now or hereinafter developed. </p>
                <p className="text">All Content is copyrighted as a collective work under copyright laws, and we own a copyright and/or database right in the selection, coordination, arrangement, presentment and enhancement of such Content. You may not modify, remove, delete, augment, add to, publish, transmit, participate in the transfer or sale of, create derivative works from or adaptations of, or in any way exploit any of the Content, in whole or in part. If no specific restrictions are displayed, you may make copies of select portions of the Content, provided that the copies are made only for your personal use and that you maintain any notices contained in the Content, such as all copyright notices, trademark legends, or other proprietary rights notices. </p>
                <p className="text">Nothing contained on the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Site or any information displayed on the Site, through the use of framing, deep linking or otherwise, except: (a) as expressly permitted by these Terms; or (b) with our prior written permission or the prior written permission from such third party that may own the trademark or copyright of information displayed on the site.</p>

                <p className="subtitle">4. COPYRIGHT INFRINGEMENT </p>
                <p className="text">Findmyresearch.com is committed to complying with copyright and related laws and require all visitors and users to comply with these laws. Accordingly, you may not use the website to store any material or content, or disseminate any material or content, in any manner that constitutes an infringement of third party intellectual property rights. </p>

                <p className="subtitle">5. ASSIGNMENT </p>
                <p className="text">You may not assign or transfer these Copyrights Terms or your rights under these Copyrights Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Copyrights Terms, or any of our rights and obligations under the Copyrights Terms, at any time without consent.</p>

                <p className="subtitle">6. LIMITATIONS </p>
                <p className="text">In no event will Findmyresearch.com be liable to you for any indirect, incidental, special, consequential or punitive damages (including damages for loss of profits, goodwill, or any other intangible loss) arising out of or relating to your access to or use of, or your inability to access or use, the website or products, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, and whether or not we have been informed of the possibility of damage.</p>

                <p className="subtitle">7. ENTIRE AGREEMENT </p>
                <p className="text">This Agreement contains the entire agreement and understanding among the parties hereto with respect to the subject matter hereof, and supersedes all prior and contemporaneous agreements, understandings, inducements and conditions, express or implied, oral or written, of any nature whatsoever with respect to the subject matter hereof. </p>
            </div >
        </PageTemplate >
    )
}

export default CopyRightPolicyPage;