import React from 'react';

import '../assets/css/PageTemplate.css';

type Props = {
    title: string,
};

const PageTemplate: React.FC<Props> = ({
    title,
    children,
}) => (
    <div id="page-template">
        <h1 className="page-title">{title}</h1>
        <div className="page-content">
            {children}
        </div>
    </div>
);

export default PageTemplate;