import React, { useEffect } from 'react';

import HomeHero from "../components/Home/HomeHero";
import HomeProjects from "../components/Home/HomeProjects/HomeProjects";
import HomeResources from "../components/Home/HomeResources";
import User from '../api/User';

const Home = () => {
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const urlToken = urlParams.get('token');
        if (urlToken) { 
            const dataToken: object = { token: urlToken };
            User.confirmEmail(dataToken);
        }
    }, [])

    return (
        <div id="home">
            <HomeHero />
            <HomeProjects />
            <HomeResources />
        </div>
    )
}

export default Home;