import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "../assets/css/Global.css";
import { useStore } from '../store/user';

import LoginAdmin from '../pages/admin/LoginAdmin';
import SideNav from '../components/admin/SideNav';
import UserList from '../pages/admin/UserList';
import ProjectList from '../pages/admin/ProjectList';
import PendingProjects from '../pages/admin/PendingProjects';
import Website from '../pages/admin/WebSite';


function Admin() {
    const user = useStore((state: any) => state.adminUser);

    if (window.location.pathname !== '/admin/login') {
        if (!user) {
            window.location.href = '/admin/login';
        }
        if (user.title !== 'Admin') {
            localStorage.clear();
            window.location.href = '/';
        }
    }
    return (
        <div id="admin-content">
            <Router>
                {!window.location.pathname.includes("/admin/login") && <SideNav />}
                <Routes>
                    <Route path='/admin/login' element={<LoginAdmin />} />
                    <Route path='/admin' element={<UserList />} />
                    <Route path='/admin/user-list' element={<UserList />} />
                    <Route path='/admin/project-list' element={<ProjectList />} />
                    <Route path='/admin/pending-projects' element={<PendingProjects />} />
                    <Route path='admin/website' element={ <Website />} />
                </Routes>
            </Router>
        </div>
    );
}

export default Admin;
