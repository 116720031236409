import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';

import "../../assets/css/HomeHero.css";

const HomeHero = () => {
    return (
        <div id="hero">
            <div className="hero-content">
                <h1 className="title-hero">Find help for your research project<br /> or <br />Find a project that needs your help</h1>
                <p className="text-hero">
                    If you are a student/trainee looking for a research project - then find a project looking for help.<br /><br />

                    If you are a researcher looking for a student to help with your research project - then post your project for students to see.<br /><br />

                    Please see the FAQ page for further details.

                </p>
                <div className="hero-btns">
                    <Button href="/projects" className="btn-hero" variant="contained">view all available projects<SearchIcon className="icon-btn" /></Button>
                    <p className="text-divider">or</p>
                    <Button href="/create-project" className="btn-hero" variant="contained">Post a project</Button>
                </div>
            </div>
        </div>
    )
}

export default HomeHero;