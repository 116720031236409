import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import '../assets/css/FAQPage.css';

const FAQPage = () => {
    return (
        <div id="faq-content">
            <div className="content-page">
                <h1 className="faq-title">Frequently Asked Questions</h1>
                <p className="topic-title">General Questions</p>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What is the purpose of this platform?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />The  goal of this platform is to give medical students / premedical students access to research opportunities to participate in remotely, while at the same time to help researchers in medicine find students / trainees to assist with their clinical research projects. In other words, this platform is an active, updated list of clinical medical research projects from reputable US institutions for which investigators are looking for assistance from students/trainees to complete.</p>
                        <p className="text"><span className="marker" />The idea is that this not only allows for more access to research opportunities to more students, but it also opens up for more mentorship opportunities and collaboration between mentors and students across the country.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Why was the platform made?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />After speaking/working with students over the past few years, it became apparent that many medical students / pre-med students had an interest in clinical research but were having difficulties finding research opportunities due to various factors. It was also expressed that students often found it difficult to find mentors, particularly those who are not in close proximity to them.</p>
                        <p className="text"><span className="marker" />In addition, many researchers/mentors expressed interest in having students assist with projects that were otherwise going unfinished, and also expressed interest in mentorship.</p>
                        <p className="text"><span className="marker" />Finally, due to recent events in the last few years, performing quality research can be done quite easily remotely. Therefore, we decided to create a platform to help students/trainees gain access to research opportunities at other institutions that could be done remotely, and also potentially create opportunities for mentorships and collaboration.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What kind of research projects are included on this platform?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />The platform allows for any type of medical research projects in all medical/surgical specialties, including case reports, review articles/literature reviews, retrospective data analyses, progressing an abstract/poster into a manuscript, and more.  Also, the projects are in different stages - from those completely fresh and ready to be started from scratch, to projects just requiring some editing or finishing writing up a manuscript.</p>
                        <p className="text"><span className="marker" />No projects requiring in person attendance/meetings or patient interactions are included in the platform - all projects should be able to be completed remotely.</p>
                        <p className="text"><span className="marker" />This is not a platform for summer research fellowships, research years, or other similar paid/unpaid dedicated long term opportunities</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Who can help out on a research project?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />Any US medical student, premedical student, resident, or other US based trainee/student interested in a career in medicine can use this platform to find a medical research project they are interested in.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle"> Who can post a research project?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />Anyone can post a research project - as long as that research project is affiliated with a principal investigator that is a licensed medical provider, and as long as the project can be completed remotely.</p>
                        <p className="text"><span className="marker" />The project can be posted by anyone - meaning residents, fellows, research coordinators, or even students, etc can all post projects. If the project is not one you are actively working on, it can still be posted by you as long as you have permission from the principal investigator (PI). Also - the project poster does not have to be the same as the point of contact - meaning anyone from an academic institution or a medical practice can post a project as long as they have permission from the point of contact / the PI.</p>
                        <p className="text"><span className="marker" />If you post a project requiring access to protected health information (PHI)  or electronic medical records (EMR), both you and the student are responsible for any PHI/EMR access authorization / IRBs / CITI training / etc required of the trainee/student that will assist you in your project..</p>
                        <p className="text"><span className="marker" /><u>Try to avoid posting projects requiring EMR access for the student,</u> as this can be difficult (many institutions require the outside student to onboard as a visiting student to gain access, which can be difficult). If you still want to post the project, please check with your institution about rules for outside student access to the EMR prior to posting the project so can guide the student through this</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I'm having some problems with my account, or having issues with posting a project. Where can I get help?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">
                            If you are having issues with verifying your account, please check your spam / promotion folder 
                            for the verification email. Also - please go ahead and add <u>contact@findmyresearch.com</u> to 
                            your address book so that you do not miss any messages from us!
                            If you continue to have issues, send an email to <a href="mailto:helpdesk@findmyresearch.com">helpdesk@findmyresearch.com</a> and 
                            we'll be of assistance as soon as we can! Head over to our "Contact Us" page for more information 
                            on how to contact us."
                        </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I can’t seem to find the email to verify my account. What can I do?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">
                        Sometimes the email may go to your <strong>spam/junk/promotions</strong> folder -  check there first. If it is not there, 
                        first - add <strong>contact@findmyresearch.com</strong> to your address book/contact list in your email address. Then,  
                        go ahead and log into your FindMyResearch account, and go to ‘My profile’. Click the ‘resend email 
                        verification’ button - and this should resend the email.  If you are still having issues, please 
                        contact us at <a href="mailto:helpdesk@findmyresearch.com">helpdesk@findmyresearch.com.</a>
                        </p>
                    </AccordionDetails>
                </Accordion>

                <p className="topic-title">SPECIFIC QUESTIONS FROM STUDENTS/TRAINEES LOOKING FOR RESEARCH</p>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What is the process once I find a project I’m interested in? And what happens after I send a message to a researcher letting them know I’m interested in their project?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />After finding a project you are interested in, send a message with your information to the project’s contact email for further communication about the project via the platform. If the researcher accepts your contact request, you’ll receive their contact information to reach out to them off the platform via your own personal email address. And remember - if the project requires access to protected health information (PHI) or to an electronic medical record (EMR), you are responsible for any PHI/EMR access authorization etc. required for the project.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Can I help with multiple projects?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">We’d suggest only taking on one project at a time in order to ensure that you are able to see the project all the way through. Researchers do not appreciate students that are unable to complete a project / see a project all the way through, and usually will not work with them again. However, you can potentially help with multiple projects. We do limit the number of contact requests you can send so that students are not mass messaging, and so others have a fair chance of obtaining projects.</p>                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Are there resources to help me with writing up my project? I’m a bit new to research</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">Yes! Head over to our Research Resources section to find step by step information on how to write case report, step by step info on how to turn a case report project into a literature review / review article, how to write a manuscript, and more!</p>

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Is there a limit to the number of project owners I can contact?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">We do limit the number of contact requests to seven per week, and limit the number of projects you can be working on to three per month. This is so students are not mass messaging project owners and to give other students a fair chance to obtain projects.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What if the project requires data review/analysis or EMR chart work?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" /><u>For a database (i.e. REDCAP, an excel sheet where patient info has already been pulled out of the EMR, or other non-EMR PHI:</u> You can still participate in a project like this and it is encouraged - often, these projects are higher impact and can be published in more reputable journals. Data review in patients with PHI that has already been pulled from the EMR into a database or excel file is usually easily accessible - BUT ensure that you have completed any PHI access authorization / IRB / CITI training that is required of you, as you are responsible for any of this required by the institution.</p>
                        <p className="text"><span className="marker" /><u>For EMR:</u> If there is EMR access required, this can be a bit more challenging. Reach out to the researcher or the institution to see if they allow for outside student EMR access, as many will not. However, often in these projects the researchers have already had someone pull the data from the EMR to a database or excel sheet. </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">When the status of the project says 'active' or 'pending completion', what does this mean?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">'Active' means the project is live and looking for student help - and so feel free to contact the project owner!</p>
                        <p className="text">'Pending completion' means that the project owner has already accepted contact requests from the number of students they were looking to find, and is tentatively no longer taking contact requests and communicating with these students. The project will then be deleted once it has fulfilled it's student need, or if the project owner deletes it manually.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I have reached out to a project owner about their project via the 'contact project owner' button - however, I have not gotten a response and it's been a while. What should I do?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">Please send an email to communication@findmyresearch.com and include the project title and school, and a member of the FindMyResearch team will investigate further. We want to make absolutely sure that we uphold our platform's reputation - and that including make sure that all projects that are posted are reputable and live, and all project owners are communicative.</p>
                        <p className="text">However, sometimes life gets in the way and it can be as simple as the researcher is taking some time off, or the emails are going to a spam folder. Regardless, let us know and we'll make sure to find out.</p>
                    </AccordionDetails>
                </Accordion>

                <p className="topic-title">QUESTIONS FROM THOSE WHO ARE POSTING A RESEARCH PROJECT</p>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What kind of projects can I post?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">You can post any type of medical research projects in any specialties, including case reports, review articles/literature reviews, retrospective data analyses, turning an abstract into a manuscript, etc. Your project can also be at any stage of completion - from those completely fresh and ready to be started from scratch, to projects just requiring some editing or finishing writing up a manuscript. No projects requiring in person attendance/meetings or patient interactions are included in the platform - all projects should be able to be completed remotely.<br />
                            This is not a platform for summer research fellowships, research years, or other similar paid/unpaid dedicated long term opportunities - any opportunities like this will not be posted or will be deleted.<br />
                            Of note, we recommend avoiding posting any projects that require students to gain access to the EMR, as this is often not allowed at institutions unless the outside student is onboarded as a visiting student, which can be difficult. See the next question for more details on this.</p>

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Can I post a project requiring access to protected health information, i.e. requiring access to chart review via the electronic medical record?</p>

                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">You can post a project that has de-identified PHI that is being used (i.e. those using REDCAP-type databases or other database projects, or projects for which patient info has been extracted from the EMR and placed into an excel spreadsheet), though both you and the student are responsible for any database/spreadsheet access authorization, CITI training, etc that may be required.<br />
                            However, we recommend avoiding posting any projects requiring EMR access/EMR chart review or access to any non de-identified PHI. As you likely know, it can be very challenging to not only have a student from another institution be covered by an IRB, but also to gain EMR access without being onboarded as a visiting student. We want to make sure this platform is a safe and effective resource for both researchers and students, and we do not want to cause any potential violations or difficulties through any institutions.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I just posted my research project. What will happen next?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />Once the project is posted, your contact info will be hidden. A student that is interested in your project will then send you a message via the platform that will show up in your email. If you are interested in communicating with that student further, you will accept their contact request. ONLY THEN will the student have access to your email address, and will contact you outside the platform via their personal email address.</p>
                        <p className="text"><span className="marker" />Your project will be taken off the platform after 30 days of you accepting a contact request from a student. HOWEVER, if that student does not take on your project, you can request to have your project reposted by going to your profile and finding your project, and requesting to repost. You can also simply repost the project via the ‘post a project’ page.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I am a research coordinator - can I post a research project for someone else?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />Absolutely - <u>as long as you have permission from the primary investigator / person who’s contact info you are listing as the point of contact</u> (so that they are not surprised with students reaching out to them about their research via the platform), you can post their research.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I’m a medical student / resident and I have access to research projects that I know are looking for student help. Can I post the project?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />Again, absolutely (even if you are not actively working on the project yourself). As long as you have permission from the PI or the resident/fellow/attending who’s contact info you are listing as the point of contact, this is not an issue.</p>
                        <p className="text"><span className="marker" />Financial compensation will be given to medical students/residents who post a project if it is accepted by a student.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I want to post a global medicine / international medicine research project, as this is a growing aspect of medical research. Can I post this?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"><span className="marker" />Absolutely. As  long as it is based out of a US institution with a principal investigator that is from that US institution. This aspect of research is quite important and gaining more and more attention, and therefore we encourage it!</p>
                        <p className="text"><span className="marker" />Again - any PHI / EMR / IRB authorization/access aspects of the project that require authorization for the student are both the researcher and the student’s responsibility.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">I posted a project, and I accepted a contact request from a student (so the project status was changed to "pending completion"). However, the student fell through. What should I do next?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">You can repost the project from your profile via the "repost" button (found next to the project on your "project management" area). Alternatively, you can wait for your project to get deleted (30 days after you accepted the contact request) and just post the project again via "post a project".</p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default FAQPage;