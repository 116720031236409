import axios from 'axios';
import Swal from 'sweetalert2'

const { REACT_APP_API_URL } = process.env;

const findAll = async (setLoading: Function, filterParams: object) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects",
            params: filterParams
        })
        setLoading(false);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const findAllAdmin = async (setLoading: Function, filterParams: object) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'get',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('@fmr/adminAuthorization')
            },
            url: REACT_APP_API_URL + "/projects/admin",
            params: filterParams
        })
        setLoading(false);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const create = async (authorization: string, data: object, setLoading: Function) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/projects",
            data: data,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        setLoading(false);
        Swal.fire({
            title: 'Success:',
            text: 'Project successfully created. Once the admin approves the project it will be displayed in the website',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        }).then(function () {
            window.location.pathname = `/`;
        });
        return response;
    }
    catch (error: any) {
        setLoading(false);
        console.log(error.response);
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const findOne = async (_id: string, authorization: string, setData: Function) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects/" + _id,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        setData(response.data);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const findFeatured = async (setLoading: Function) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects/featured"
        })
        setLoading(false);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const addFavorite = async (authorization: string, id: string, isFavorite: boolean) => {
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/users/favorite-projects/" + id,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        Swal.fire({
            title: 'Success:',
            text: (!isFavorite ? "Project successfully favorited" : "Favorite successfully removed"),
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        });
        return response;
    }
    catch (error: any) {
        console.log(error.response);
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const findPosted = async (setLoading: Function, authorization: string, filterParams: object) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/users/posted-projects",
            params: filterParams,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        setLoading(false);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const findFavorites = async (setLoading: Function, authorization: string, filterParams: object) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/users/favorite-projects",
            params: filterParams,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        setLoading(false);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const findPending = async (setLoading: Function, authorization: string, filterParams: object) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects/pending",
            params: filterParams,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        setLoading(false);
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const applyToAProject = async (authorization: string, projectId: string, message: string, setLoadingContact: Function, requests: number, setIsRequested: Function) => {
    try {
        const response = await axios({
            method: 'post',
            data: { "message": message },
            url: REACT_APP_API_URL + "/projects/apply/" + projectId,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        Swal.fire({
            title: 'Success:',
            text: `Message sent successfully (${requests}/7) remaining applications for this week.`,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        });
        setIsRequested(true)
        setLoadingContact(false);
        return response;
    }
    catch (error: any) {
        console.log(error.response);
        Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        setLoadingContact(false);
        return error;
    }
}

const deleteProject = async (authorization: string, id: string) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the project?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'delete',
                    url: REACT_APP_API_URL + "/projects/" + id,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                Swal.fire({
                    title: 'Success:',
                    text: 'Project successfully deleted',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    if (window.location.pathname.includes("details")) {
                        window.location.pathname = '/projects';
                    } else {
                        window.location.reload();
                    }
                });
                return response;
            }
            catch (error: any) {
                Swal.fire({
                    title: 'Error',
                    text: (error.response.data.message),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

const updateProject = async (authorization: string, data: object, setLoading: Function, id: any) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'put',
            url: REACT_APP_API_URL + "/projects/" + id,
            data: data,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        setLoading(false);
        Swal.fire({
            title: 'Success:',
            text: 'Project successfully updated',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        }).then(function () {
            window.location.pathname = `details/${response.data._id}`;
        });
        return response;
    }
    catch (error: any) {
        setLoading(false);
        console.log(error.response);
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const findApplicants = async (id: any, authorization: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects/find-applicants/" + id,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const acceptApplicant = async (authorization: string, data: object) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to accept this applicant?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'post',
                    url: REACT_APP_API_URL + "/projects/accept-user",
                    data: data,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                Swal.fire({
                    title: 'Success:',
                    text: 'Applicant accepted',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    window.location.reload();
                });
                return response;
            }
            catch (error: any) {
                Swal.fire({
                    title: 'Error',
                    text: (error.response.data.message),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

const acceptApplicantEmail = async (userId: string, projectId: string, token: string) => {
    const data = { userId, projectId, token }
    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to accept this applicant?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'post',
                    url: REACT_APP_API_URL + "/projects/accept-user-email",
                    data: data,
                })
                Swal.fire({
                    title: 'Success:',
                    text: 'Applicant accepted',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    window.location.href = `/details/${projectId}`;
                });
                return response;
            }
            catch (error: any) {
                Swal.fire({
                    title: 'Error',
                    text: (error.response.data.message),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    window.location.href = `/details/${projectId}`;
                });
                return error;
            }
        } else {
            window.location.href = `/details/${projectId}`;
        }
    });
}

const declineApplicant = async (authorization: string, data: object) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to decline this applicant?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'post',
                    url: REACT_APP_API_URL + "/projects/decline-user",
                    data: data,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                Swal.fire({
                    title: 'Success:',
                    text: 'Applicant declined',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    window.location.reload();
                });
                return response;
            }
            catch (error: any) {
                Swal.fire({
                    title: 'Error',
                    text: (error.response.data.message),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

const removeAccepted = async (authorization: string, data: object) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to remove this applicant?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'post',
                    url: REACT_APP_API_URL + "/projects/remove-accepted-applicant",
                    data: data,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                Swal.fire({
                    title: 'Success:',
                    text: 'Applicant removed',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    window.location.reload();
                });
                return response;
            }
            catch (error: any) {
                Swal.fire({
                    title: 'Error',
                    text: (error.response.data.message),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

const findAccepted = async (id: any, authorization: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects/find-accepted-applicants/" + id,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const findDenied = async (id: any, authorization: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/projects/find-declined-applicants/" + id,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const reactivate = async (authorization: string, projectId: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/projects/reactivate/" + projectId,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        Swal.fire({
            title: 'Success:',
            text: 'Project reativated',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        });
        return response;
    }
    catch (error: any) {
        console.log(error.response);
        Swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const approveProject = async (authorization: string, projectId: string, removeSingleProject: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to approve this project?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'post',
                    url: REACT_APP_API_URL + "/projects/accept/" + projectId,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                Swal.fire({
                    title: 'Success:',
                    text: `Project approved`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                }).then(function () {
                    if(removeSingleProject) {
                        removeSingleProject(projectId);
                    } else {
                        window.location.reload();
                    }
                });
                return response;
            }
            catch (error: any) {
                console.log(error.response);
                Swal.fire({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

export default {
    findAll,
    create,
    findOne,
    findFeatured,
    addFavorite,
    findPosted,
    findFavorites,
    findPending,
    applyToAProject,
    deleteProject,
    updateProject,
    findApplicants,
    findDenied,
    acceptApplicant,
    declineApplicant,
    removeAccepted,
    findAccepted,
    reactivate,
    approveProject,
    acceptApplicantEmail,
    findAllAdmin
};
