import axios from 'axios';
import Swal from 'sweetalert2'
const { REACT_APP_API_URL } = process.env;

const register = async (data: object, password: string, setLoading: Function) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/auth/register",
            data: { ...data, password: password }
        })
        await findOne(response.data._id);
        localStorage.setItem("@fmr/userAuthorization", response.data.access_token);
        localStorage.setItem("@fmr/userLoginDate", String(new Date()));
        localStorage.setItem("@fmr/userLogged", "true");
        await Swal.fire({
            title: 'Success',
            text: 'Thank you for registering! Please check your email inbox (including your spam or promotion folder) for an email from us to verify your email address prior to being able to use the website functions. Also - please add contact@findmyresearch.com to your address book so that you don\'t miss any messages from us!',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        setLoading(false);
        window.location.href = "/my-profile";
        return response;
    }
    catch (error: any) {
        if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/).test(password)) {
            Swal.fire({
                title: 'Password must have:',
                html: 'One upper letter<br>One lower letter<br>One number<br>One special character<br>Eight in length',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        } else {
            Swal.fire({
                title: 'Error',
                text: (error.response.data.message),
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        }
        setLoading(false);
        return error;
    }
}

const login = async (data: object, setLoading: Function, setErrorPassword: Function, urlToken: any) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/auth/login",
            data: data
        }).then(async function (response) {
            await findOne(response.data._id);
            localStorage.setItem("@fmr/userAuthorization", response.data.access_token);
            localStorage.setItem("@fmr/userLoginDate", String(new Date()));
            localStorage.setItem("@fmr/userLogged", "true");
            setLoading(false);
        });
        if (urlToken) {
            window.location.href = `/?token=${urlToken}`
        } else {
            window.location.href = "/"
        }
        return response;
    }
    catch (error: any) {
        setLoading(false);
        error.response.data.message == "Incorrect password." ? setErrorPassword(true) : setErrorPassword(false);
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const findOne = async (id: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/users/" + id,
        })
        localStorage.setItem("@fmr/user", JSON.stringify(response.data));
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const loginAdmin = async (data: object, setLoading: Function, setErrorPassword: Function) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/auth/login",
            data: data
        })
        await findOneAdmin(response.data._id);
        localStorage.setItem("@fmr/adminAuthorization", response.data.access_token);
        localStorage.setItem("@fmr/adminLoginDate", String(new Date()));
        setLoading(false);
        return response;
    }
    catch (error: any) {
        setLoading(false);
        error.response.data.message == "Incorrect password." ? setErrorPassword(true) : setErrorPassword(false);
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const findOneAdmin = async (id: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/users/" + id,
        })
        if (response.data.title != "Admin") {
            Swal.fire({
                title: 'Unauthorized user',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        } else {
            localStorage.setItem("@fmr/admin", JSON.stringify(response.data));
            localStorage.setItem("@fmr/adminLogged", "true");
            window.location.href = "/admin";
            return response;
        }
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const findAll = async (authorization: string, filter: object) => {
    try {
        const response = await axios({
            method: 'get',
            url: REACT_APP_API_URL + "/users",
            params: filter,
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const toggleAdmin = async (authorization: string, id: string, name: string, isAdmin: boolean) => {
    Swal.fire({
        title: 'Are you sure?',
        text: (`Are you sure you want to ${isAdmin ? "remove" : "make"} ${name} as an administrator?`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'post',
                    url: REACT_APP_API_URL + "/users/toggle-admin/" + id,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                window.location.href = "/admin/user-list";
                return response;
            }
            catch (error: any) {
                console.log(error.response);
                Swal.fire({
                    title: 'Error',
                    text: 'There is an error with the proccess',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

const Delete = async (authorization: string, id: string, name: string) => {
    Swal.fire({
        title: 'Are you sure?',
        text: (`Are you sure you want to delete ${name}`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: "#1976d2"
    }).then(async function (result) {
        if (result.isConfirmed) {
            try {
                const response = await axios({
                    method: 'delete',
                    url: REACT_APP_API_URL + "/users/" + id,
                    headers: {
                        Authorization: 'Bearer ' + authorization
                    }
                })
                window.location.href = "/admin/user-list";
                return response;
            }
            catch (error: any) {
                console.log(error.response);
                Swal.fire({
                    title: 'Error',
                    text: 'There is an error with the proccess',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#1976d2"
                })
                return error;
            }
        }
    });
}

const update = async (data: object, password: string, setLoading: Function, id: string, authorization: string) => {
    if (password != "") {
        if (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/).test(password)) {
            Swal.fire({
                title: 'Password must have:',
                html: 'One upper letter<br>One lower letter<br>One number<br>One special character<br>Eight in length',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        }
    }
    setLoading(true);
    try {
        if (password != "") {
            const response = await axios({
                method: 'put',
                url: REACT_APP_API_URL + "/users/" + id,
                data: { ...data, password: password },
                headers: {
                    Authorization: 'Bearer ' + authorization
                }
            })
            setLoading(false);
            window.location.href = "/my-profile";
            localStorage.setItem("@fmr/user", JSON.stringify(response.data));
            return response;
        } else {
            const response = await axios({
                method: 'put',
                url: REACT_APP_API_URL + "/users/" + id,
                data: data,
                headers: {
                    Authorization: 'Bearer ' + authorization
                }
            })
            setLoading(false);
            window.location.href = "/my-profile";
            localStorage.setItem("@fmr/user", JSON.stringify(response.data));
            return response;
        }
    }
    catch (error) {
        setLoading(false);
        return error;
    }
}

const forgotPassword = async (data: object, setModalActive: Function) => {
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/auth/forgot-password",
            data: data
        })
        Swal.fire({
            title: 'Success:',
            text: 'Check your email to reset password',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        });
        setModalActive(false);
        return response;
    }
    catch (error: any) {
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

const resetPassword = async (data: object, password: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/auth/reset-password",
            data: { ...data, password: password }
        })
        Swal.fire({
            title: 'Success:',
            text: 'Password reset successfully',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        }).then(async function () {
            window.location.href = "/login";
        });
        return response;
    }
    catch (error: any) {
        if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/).test(password)) {
            Swal.fire({
                title: 'Password must have:',
                html: 'One upper letter<br>One lower letter<br>One number<br>One special character<br>Eight in length',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        } else {
            Swal.fire({
                title: 'Error',
                text: (error.response.data.message),
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        }
        return error;
    }
}


const confirmEmail = async (data: object) => {
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/auth/confirm-email",
            data: data,
        })
        Swal.fire({
            title: 'You\'re almost there!',
            text: 'Email confirmed. You may now login to your account',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        }).then(function () {
            localStorage.clear();
            window.location.href = "/login";
        });
        return response;
    }
    catch (error: any) {
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        }).then(function () {
            window.location.href = "/login";
        });
        return error;
    }
}

const getIp = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://geolocation-db.com/json/',
        })
        return response.data.IPv4;
    }
    catch (error) {
        console.log(error);
        return error;
    }
}

const resendConfirmationEmail = async (authorization: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: REACT_APP_API_URL + "/users/resend-verification-email",
            headers: {
                Authorization: 'Bearer ' + authorization
            }
        })
        Swal.fire({
            title: 'Success:',
            text: 'Email sent. Check your email to confirm your account. Don\'t forget to check your spam folder.',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return response;
    }
    catch (error: any) {
        Swal.fire({
            title: 'Error',
            text: (error.response.data.message),
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        })
        return error;
    }
}

export default {
    register,
    login,
    findOne,
    loginAdmin,
    findAll,
    toggleAdmin,
    update,
    forgotPassword,
    resetPassword,
    confirmEmail,
    getIp,
    resendConfirmationEmail,
    Delete
};