import React, { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PageHeader from '../components/PageHeader';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import NoProjectsFound from '../components/ProjectList/NoProjectsFound';
import Projects from '../api/Projects';
import ProjectCard from '../components/ProjectList/ProjectCard';
import IProjects from '../interfaces/IProjects';
import IProjectItem from '../interfaces/IProjectItem';
import IFilterProjects from '../interfaces/IFilterProjects';
import '../assets/css/MyProfile.css';
import { useStore } from '../store/user';
import User from '../api/User';

const MyProfile = () => {
    const user = useStore((state: any) => state.user);
    const fetchUser = useStore((state: any) => state.fetchUser);
    const [listProjects, setListProjects] = useState<IProjects>();
    const [listTitle, setListTitle] = useState<string>("Posted Projects");
    const [loading, setLoading] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filterParams, setFilterParams] = useState<Partial<IFilterProjects>>({
        limit: 5,
    });

    const fetchFavorites = async () => {
        filterParams.page = 1
        setListTitle("Saved Projects");
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const projectsItems = await Projects.findFavorites(setLoading, authorization, filterParams);
        projectsItems.favoritedProjects = await checkFavoritedProjects(projectsItems.favoritedProjects);
        setTotalPages(projectsItems.pages)
        projectsItems.favoritedProjects.length == 0 ? setListProjects({ projects: undefined }) : setListProjects({ projects: projectsItems.favoritedProjects });
        
    };

    useEffect(() => {
        fetchProjects(listTitle);
    }, [filterParams]);

    const checkFavoritedProjects = async (projectList: IProjectItem[]) => {
        for (const project of projectList) {
            project.isFavorite = false
            for (const favorited of user.favoritedProjects) {
                if(favorited === project._id) {
                    project.isFavorite = true
                }
            }
        }
        return projectList
    }

    const fetchPosted = async () => {
        setListTitle("Posted Projects");
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const projectsItems = await Projects.findPosted(setLoading, authorization, filterParams);
        projectsItems.postedProjects = await checkFavoritedProjects(projectsItems.postedProjects);
        setTotalPages(projectsItems.pages)
        projectsItems.postedProjects.length == 0 ? setListProjects({ projects: undefined }) : setListProjects({ projects: projectsItems.postedProjects });
    };

    const itemsProjects = () => {
        if (listProjects?.projects == undefined) {
            return <NoProjectsFound message="No projects projects found" />
        } else {
            return listProjects?.projects?.map((project: IProjectItem) => {
                return <ProjectCard
                    acceptedApplicants={/*project.acceptedApplicants.length*/ 0}
                    projectTitle={project.projectTitle}
                    numberOfStudents={project.numberOfStudents}
                    maxNumberOfStudents={project.maxNumberOfStudents}
                    projectDescription={project.projectDescription}
                    createdAt={project.createdAt}
                    _id={project._id}
                    status={project.status}
                    speciality={project.speciality}
                    typeOfResearch={project.typeOfResearch}
                    school={project.school}
                    key={project._id}
                    refetch={fetchFavorites}
                />;
            })
        }
    }

    const handleLogout = () => {
        localStorage.removeItem("@fmr/userLogged");
        localStorage.removeItem("@fmr/user");
        localStorage.removeItem("@fmr/userAuthorization");
        localStorage.removeItem("@fmr/userLoginDate");
        window.location.href = "/";
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setFilterParams({ ...filterParams, page: value });
    };

    const fetchProjects = async (type: string) => {
        await fetchUser(user);
        type === "Posted Projects" ? fetchPosted() : fetchFavorites();
    }

    const handleResendEmail = async () => {
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        await User.resendConfirmationEmail(authorization);
    }

    return (
        <div id="my-profile">
            <PageHeader title="My Profile" />
            <div className="content-profile">
                <div className="box-user">
                    <div className="user-management">
                        <div className="management-header">
                            <h2 className="user-name">{`${user?.firstName}  ${user?.lastName}`}</h2>
                            <IconButton title="Edit Profile" component="span">
                                <a className="edit-icon" href={`/edit-user/${user.id}`}><EditIcon /></a>
                            </IconButton>
                        </div>
                        <div className="user-actions">
                            <p className="user-title">{user?.title}</p>
                            <IconButton title="Logout" component="span" onClick={handleLogout} >
                                <LogoutIcon />
                            </IconButton>
                        </div>
                        <p className="user-email">Email address: <span>{user?.email}</span></p>
                        {user.isEmailConfirmed === false && <Button className="email-btn" color="primary" variant="contained" onClick={handleResendEmail}>Resend email verification</Button>}
                        <div className="management-buttons">
                            <Button className="management-btn" color="primary" variant="contained" onClick={fetchPosted}><EditIcon className="btn-icon" />Project management</Button>
                            <Button className="management-btn" color="primary" variant="outlined" onClick={() => listTitle === 'Saved Projects' ? fetchPosted() : fetchFavorites()}><StarIcon className="btn-icon star-icon" /> 
                            {listTitle === 'Saved Projects' ? 'Posted Projects' : 'Saved Projects'}
                            </Button>
                        </div>
                    </div>
                    <div className="user-info">
                        <ul>
                            <li><p className="info">Posted projects</p><p className="info-content">{user?.postedProjects?.length} {`active project${user?.postedProjects?.length > 1 ? 's' : ''}`}</p></li>
                            <li><p className="info">Favorite projects</p><p className="info-content">{user?.favoritedProjects?.length} {`favorite project${user?.favoritedProjects?.length > 1 ? 's' : ''}`}</p></li>
                            {user?.title !== "Researcher/Project Poster" &&
                                <>
                                    <li><p className="info">Number of acceptances (Monthly)</p><p className="info-content">{`${user?.numberOfAcceptances}/3`}</p></li>
                                    <li><p className="info">Number of requests (Weekly)</p><p className="info-content">{`${user?.numberOfRequests}/7`}</p></li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
                <PageHeader title={listTitle} />
                <div className="projects-list">
                    {loading ? <CircularProgress className="loading-projects" /> : itemsProjects()}
                    {listProjects?.projects === undefined ?
                        <span /> :
                        <Pagination
                            className="projects-pagination"
                            page={filterParams.page || 1}
                            onChange={handlePageChange}
                            count={totalPages}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default MyProfile;