import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2'

import PageHeader from '../components/PageHeader';
import specialityList from '../utils/SpecialityList.json';
import projectTypes from '../utils/ProjectTypes.json';
import statesList from '../utils/StatesList.json';
import '../assets/css/CreateProject.css';

import Projects from '../api/Projects';
import IProjectItem from '../interfaces/IProjectItem';
import IStateItem from '../interfaces/IStateItem';

const CreateProject = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectContactTitle, setSelectContactTitle] = useState<string>("");
    const [showContactInput, setShowContactInput] = useState<boolean>(false);
    const [selectType, setSelectType] = useState<string>("");
    const [showTypeInput, setShowTypeInput] = useState<boolean>(false);
    const [isBoxChecked, setIsBoxChecked] = useState<boolean>(false);
    const [isLogged, setIsLogged] = useState<boolean>(true);
    const [data, setData] = useState<Partial<IProjectItem>>({
        typeOfResearch: "Case Report/Case Series"
    });

    useEffect(() => {
        if (localStorage.getItem("@fmr/userLogged") == "true") {
            setIsLogged(true);
        } else {
            setIsLogged(false);
            window.location.href = "/login";
        }
    }, [])

    useEffect(() => {
        handleContactTitle();
    }, [selectContactTitle])

    useEffect(() => {
        handleType();
    }, [selectType])

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('@fmr/user')!);
        if (userInfo.title != "Researcher/Project Poster") {
            alertUser();
        }
    }, [])

    const handleType = () => {
        if (selectType == "Other") {
            setShowTypeInput(true);
            setData({ ...data, typeOfResearch: '' });
        } else {
            setShowTypeInput(false);
            setData({ ...data, typeOfResearch: selectType });
        };
    }

    const handleContactTitle = () => {
        if (selectContactTitle == "Other") {
            setShowContactInput(true);
            setData({ ...data, contactTitle: '' });
        } else {
            setShowContactInput(false);
            setData({ ...data, contactTitle: selectContactTitle });
        };
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        Projects.create(authorization, data, setLoading);
    }

    const alertUser = () => {
        Swal.fire({
            text: 'Only researchers can post projects',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: "#1976d2"
        }).then(function () {
            window.location.href = "/";
        })
    }

    return (
        <div id="create-project">
            <PageHeader title="Post a project" />
            <form className="form-content" onSubmit={handleSubmit}>
                <div className="form-section">
                    <h2 className="form-title">Project information</h2>
                    <p className="form-subtitle">Only post projects that can be performed remotely (do not require in-person participation) - see FAQ for details</p>
                    <div className="inputs-section">
                        <TextField
                            required
                            className="form-741"
                            id="outlined"
                            label="Project title *"
                            value={data.projectTitle || ''}
                            onChange={e => setData({
                                ...data,
                                projectTitle: e.target.value
                            })}
                        />
                        <FormControl
                            fullWidth
                            className="form-363"
                        >
                            <InputLabel>Type of Research Project *</InputLabel>
                            <Select
                                label="Type of Research Project"
                                value={selectType || ''}
                                onChange={(e) => setSelectType(e.target.value)}
                            >
                                {projectTypes?.types?.map((type: string) => {
                                    return <MenuItem value={type}>{type}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    {showTypeInput &&
                        <div className="inputs-section other-section">
                            <TextField
                                className="form-363"
                                required
                                id="outlined"
                                type="text"
                                label="Other type *"
                                value={data.typeOfResearch || ''}
                                onChange={e => setData({
                                    ...data,
                                    typeOfResearch: e.target.value
                                })}
                            />
                        </div>
                    }
                    <div className="inputs-section">
                        <FormControl
                            fullWidth
                            className="form-363">
                            <InputLabel>Number of students needed *</InputLabel>
                            <Select
                                label="Number of students needed *"
                                value={data.maxNumberOfStudents || ''}
                                onChange={e => setData({
                                    ...data,
                                    maxNumberOfStudents: e.target.value as number
                                })}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            className="form-363">
                            <InputLabel>Specialty *</InputLabel>
                            <Select
                                label="Specialty *"
                                value={data.speciality || ''}
                                onChange={e => setData({
                                    ...data,
                                    speciality: e.target.value
                                })}
                            >
                                {specialityList?.specialities?.map((speciality: string) => {
                                    return <MenuItem value={speciality}>{speciality}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            className="form-363">
                            <InputLabel>Will project require PHI access *</InputLabel>
                            <Select
                                label="Will project require PHI access *"
                                value={data.trainingRequired || ''}
                                onChange={e => setData({
                                    ...data,
                                    trainingRequired: e.target.value
                                })}
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <TextField
                        id="outlined-multiline-static"
                        label="Project description *"
                        multiline
                        rows={4}
                        placeholder="Very briefly describe the research project and the student's role."
                        value={data.projectDescription || ''}
                        onChange={e => setData({
                            ...data,
                            projectDescription: e.target.value
                        })}
                    />
                </div>
                <div className="page-divider" />
                <div className="form-section">
                    <h2 className="form-title">Personal information of the point of contact</h2>
                    <p className="form-subtitle">Name / email will be hidden upon posting</p>
                    <div className="inputs-section">
                        <FormControl
                            fullWidth
                            className="form-363">
                            <InputLabel>State *</InputLabel>
                            <Select
                                label="State *"
                                value={data.state || ''}
                                onChange={e => setData({
                                    ...data,
                                    state: e.target.value
                                })}
                            >
                                {statesList?.states?.map((state: IStateItem) => {
                                    return <MenuItem value={state.name}>{state.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            className="form-363"
                            required
                            id="outlined"
                            label="School / Institution *"
                            value={data.school || ''}
                            onChange={e => setData({
                                ...data,
                                school: e.target.value
                            })}
                        />
                        <TextField
                            className="form-363"
                            required
                            id="outlined"
                            type="email"
                            label="Email address *"
                            value={data.email || ''}
                            onChange={e => setData({
                                ...data,
                                email: e.target.value
                            })}
                        />
                    </div>
                    <div className="inputs-section">
                        <FormControl className="form-363">
                            <InputLabel>Contact title *</InputLabel>
                            <Select
                                label="Contact title *"
                                value={selectContactTitle || ''}
                                onChange={e => setSelectContactTitle(e.target.value)}
                            >
                                <MenuItem value="Attending">Attending</MenuItem>
                                <MenuItem value="Resident">Resident</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                        <div className="box-contact" onClick={() => setIsBoxChecked(!isBoxChecked)} >
                            <Checkbox checked={isBoxChecked} />
                            <p className="text-contact">I verify the point of contact has consented to be contacted for this project.</p>
                        </div>
                    </div>
                    {showContactInput &&
                        <TextField
                            className="form-363"
                            required
                            id="outlined"
                            type="text"
                            label="Other title *"
                            value={data.contactTitle || ''}
                            onChange={e => setData({
                                ...data,
                                contactTitle: e.target.value
                            })}
                        />
                    }
                </div>
                <Button disabled={!isBoxChecked} type="submit" className="btn-submit" variant="contained">
                    {!loading ? 'Submit' : <CircularProgress color="inherit" />}
                </Button>
            </form>
        </div>
    )
}

export default CreateProject;