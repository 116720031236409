import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import '../../assets/css/NoProjectsFound.css';

const NoProjectsFound = ({ message }: {
    message: string
}) => {
    return (
        <div id="no-projects-found">
            <ErrorOutlineIcon className="error-icon" />{message}
        </div>
    )
}

export default NoProjectsFound;