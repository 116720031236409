import React, { useState, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import NoProjectsFound from '../../components/ProjectList/NoProjectsFound';
import IFilterUsers from '../../interfaces/IFilterUsers';
import IUsers from '../../interfaces/IUsers';
import IUser from '../../interfaces/IUser';
import User from '../../api/User';
import UserCard from '../../components/admin/UserCard';
import '../../assets/css/Admin.css';
import '../../assets/css/UserList.css';

const UserList = () => {
    const [listUsers, setListUsers] = useState<IUsers>();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [dataFilter, setDataFilter] = useState<Partial<IFilterUsers>>({});

    useEffect(() => {
        if (dataFilter.email == "") {
            delete dataFilter.email;
        }
        fetchUsers();
    }, [dataFilter.title, dataFilter.page])

    const fetchUsers = async () => {
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        const usersItems = await User.findAll(authorization, dataFilter);
        setTotalPages(usersItems.pages);
        usersItems.users.length == 0 ? setListUsers({ users: undefined }) : setListUsers({ users: usersItems.users });
        setLoading(false);
    };

    const itemsUsers = () => {
        if (listUsers?.users == undefined) {
            return <NoProjectsFound message="No users found" />
        } else {
            return listUsers?.users?.map((user: IUser) => {
                return <UserCard
                    name={`${user.firstName} ${user.lastName}`}
                    email={user.email}
                    title={user.title}
                    id={user._id}
                    ip={user.ip}
                />;
            })
        }
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setDataFilter({ ...dataFilter, page: value });
    };

    return (
        <div className="content-admin">
            <h1 className="admin-title">User List</h1>
            <div className="admin-header">
                <div className="box-search">
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Search by email</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            value={dataFilter.email}
                            onChange={e => setDataFilter({
                                ...dataFilter,
                                email: e.target.value
                            })}
                            endAdornment={
                                <InputAdornment onClick={fetchUsers} position="end">
                                    <IconButton sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <FormControl className="box-title">
                    <InputLabel>Search by Title</InputLabel>
                    <Select
                        label="Search by Title"
                        value={dataFilter.title || ''}
                        onChange={e => setDataFilter({
                            ...dataFilter,
                            title: e.target.value
                        })}
                    >
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="Student/Trainee">Student/Trainee</MenuItem>
                        <MenuItem value="Researcher/Project Poster">Researcher/Project Poster</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div id="user-list">
                {loading ? <CircularProgress className="loading-list-admin" /> :
                    <table className="admin-table" >
                        <tr className="table-header">
                            <th className="header-item">Name</th>
                            <th className="header-item">E-mail</th>
                            <th className="header-item">Title</th>
                            <th className="header-item">IP</th>
                            <th className="header-item header-btn" />
                            <th className="header-item header-btn" />
                        </tr>
                        {itemsUsers()}
                    </table>
                }
                <Pagination
                    className="admin-pagination"
                    page={dataFilter.page || 1}
                    onChange={handlePageChange}
                    count={totalPages}
                />
            </div>
        </div>
    )
}

export default UserList;