import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";

import IUser from '../../interfaces/IUser';
import Logo from '../../assets/images/white-logo.svg';
import "../../assets/css/SideNav.css";

const SideNav = () => {
    interface IAccordion {
        item1: boolean,
        item2: boolean,
        item3: boolean
    }

    const [itemIndex, setItemIndex] = useState<string>("");
    const [user, setUser] = useState<Partial<IUser>>({});
    const [accordion, setAccordion] = useState<Partial<IAccordion>>({
        item1: false,
        item2: false,
        item3: false
    });

    const handleLogout = () => {
        localStorage.removeItem('@fmr/adminLoginDate');
        localStorage.removeItem("@fmr/adminLogged");
        localStorage.removeItem("@fmr/adminAuthorization");
        window.location.href = "/admin/login";
    }

    useEffect(() => {
        checkLoginDate();
    }, [])

    const checkLoginDate = () => {
        const loginDateRaw = localStorage.getItem("@fmr/adminLoginDate")!;
        const loginDate = new Date(loginDateRaw);
        const now = new Date();
        var diff = Math.abs(loginDate.getTime() - now.getTime()) / 3600000;
        if (diff > 24) {
            localStorage.removeItem('@fmr/adminLoginDate');
            localStorage.removeItem("@fmr/adminLogged");
            window.location.href = "/admin/login";
        } else {
            setUser(JSON.parse(localStorage.getItem('@fmr/admin')!))
        }
    };

    return (
        <div id="side-nav">
            <div className="box-logo">
                <img src={Logo} alt="Logo" />
            </div>
            <nav className="nav-menu">
                <div className="accordion">
                    <Button onClick={() => setAccordion({ ...accordion, item1: !accordion.item1 })} className="accordion-btn">
                        <PersonOutlineIcon className="icon-btn" />
                        Users
                        <KeyboardArrowDownIcon className={`arrow-btn arrow-btn-${accordion.item1}`} />
                    </Button>
                    <ul className={`accordion-list accordion-list-${accordion.item1}`}>
                        <Button onClick={() => setItemIndex("item11")} className={`accordion-item accordion-item-${itemIndex == "item11"}`}>
                            <a href="/admin/user-list">User List</a>
                        </Button>
                    </ul>
                </div>
                <div className="accordion">
                    <Button onClick={() => setAccordion({ ...accordion, item2: !accordion.item2 })} className="accordion-btn">
                        <DescriptionIcon className="icon-btn" />
                        Projects
                        <KeyboardArrowDownIcon className={`arrow-btn arrow-btn-${accordion.item2}`} />
                    </Button>
                    <ul className={`accordion-list accordion-list-${accordion.item2}`}>
                        <Button onClick={() => setItemIndex("item21")} className={`accordion-item accordion-item-${itemIndex == "item21"}`}>
                            <a href="/admin/project-list">Project List</a>
                        </Button>
                    </ul>
                    <ul className={`accordion-list accordion-list-${accordion.item2}`}>
                        <Button onClick={() => setItemIndex("item22")} className={`accordion-item accordion-item-${itemIndex == "item22"}`}>
                            <Link to="/admin/pending-projects">Pending approval</Link>
                        </Button>
                    </ul>
                </div>
                <div className="accordion">
                <Link to="/admin/website">
                    <Button className="accordion-btn">
                        <PersonOutlineIcon className="icon-btn" />
                        Website
                    </Button>
                </Link>
                </div>
            </nav >
            <div className="box-user">
                <div className="name-user">{user.firstName!}</div>
                <LogoutIcon onClick={handleLogout} className="icon-logout" />
            </div>
        </div >
    )
}

export default SideNav;