import React, { useState, useEffect } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Projects from '../../api/Projects';
import CalcDays from '../../utils/CalcDays';
import IProjectItem from '../../interfaces/IProjectItem';
import IUser from '../../interfaces/IUser';

type AcceptedApplicants = {
    _id: number,
    user: IUser
}

type projectCardParams = {
    title: string,
    status: string,
    createdAt: Date,
    type: string,
    speciality: string,
    _id: string,
    isActive: boolean,
    acceptedApplicants: AcceptedApplicants[],
    projectDescription: string,
    maxNumberOfStudents: number,
    numberOfStudents: number,
    state: string,
    removeSingleProject?: Function
    email?: string,
}

const ProjectCard = (
    {
        title,
        status,
        createdAt,
        type,
        speciality,
        _id,
        isActive,
        acceptedApplicants,
        projectDescription,
        maxNumberOfStudents,
        numberOfStudents,
        state,
        removeSingleProject,
        email
    }: projectCardParams) => {
    const [days, setDays] = useState<number>(0);
    const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
    const [modalActive, setModalActive] = useState<boolean>(false);

    const cardDays = async () => {
        const numberDays = await CalcDays(createdAt);
        if (numberDays >= 30) setBtnEnabled(true);
        setDays(numberDays);
    };

    const deleteProject = async () => {
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        await Projects.deleteProject(authorization, _id);
    }

    const repostProject = async () => {
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        await Projects.reactivate(authorization, _id);
    }

    const aproveProject = async () => {
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        await Projects.approveProject(authorization, _id, removeSingleProject!);
    }

    useEffect(() => {
        cardDays();
    }, []);

    return (
        <tr className="table-content">
            <td className="table-item view-btn">
                <IconButton sx={{ p: '10px' }} aria-label="view" title="View details">
                    <VisibilityIcon onClick={() => setModalActive(!modalActive)} />
                </IconButton>
            </td>
            <td className="table-item">{title}</td>
            <td className="table-item">{status}</td>
            <td className="table-item">{type}</td>
            <td className="table-item">{speciality}</td>
            <td className="table-item">{days}</td>
            {isActive ?
                <>
                    <td className="table-btn"><Button variant="contained" disabled={!btnEnabled} onClick={repostProject}>Repost</Button></td>
                    <td className="table-btn"><Button variant="contained" color="error" onClick={deleteProject}><DeleteIcon /></Button></td>
                </>
                :
                <>
                    <td className="table-btn"><Button variant="contained" onClick={aproveProject}>Approve</Button></td>
                    <td className="table-btn"><Button variant="contained" color="error" onClick={deleteProject}><DeleteIcon /></Button></td>
                </>
            }

            <div className={modalActive ? "content-modal" : "content-modal modal-inactive"}>
                <div onClick={() => setModalActive(!modalActive)} className="background-modal" />
                <div className="box-modal">
                    <div className="header-modal">
                        <IconButton sx={{ p: '10px' }} aria-label="view" title="View details">
                            <CloseIcon onClick={() => setModalActive(!modalActive)} />
                        </IconButton>
                    </div>
                    <h2 className="title-modal">{title}</h2>
                    <div className="project-infos">
                        <p><strong>Created at:</strong> {new Date(createdAt)?.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</p>
                        <p><strong>Type:</strong> {type}</p>
                        <p><strong>Speciality:</strong> {speciality}</p>
                        <p><strong>Number Of Students:</strong> {numberOfStudents} of {maxNumberOfStudents}</p>
                        <p><strong>State:</strong> {state}</p>
                        <p><strong>Contact Info:</strong> {email}</p>
                    </div>
                    <div className="project-description">
                        {projectDescription}
                    </div>
                    <div className='project-accepted-students'>
                        <h3>Accepted Students</h3>
                        {/* show name and email of every accepted stunded */}
                        {acceptedApplicants.map((acceptedApplicant: AcceptedApplicants) => {
                            if(acceptedApplicant.user) {
                                return (
                                    <div className='accepted-student'>
                                        <p><strong>Name:</strong> {acceptedApplicant.user?.firstName} {acceptedApplicant.user?.lastName}</p>
                                        <p><strong>Email:</strong> {acceptedApplicant.user?.email}</p>
                                    </div>
                                )
                            }
                            
                        })}
                    </div>
                </div>
            </div>
        </tr>
    )
}

export default ProjectCard;