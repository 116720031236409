import React from 'react';

import PageTemplate from '../components/PageTemplate';

const PrivacyPolicy = () => {
    return (
        <PageTemplate title="Privacy Policy">
            <div id="privacy-content">
                <p className="subtitle">1. OUR COMMITMENT TO YOUR PRIVACY</p>

                <p className="text">Our Privacy Policy tells you what personally identifiable information Matrix Codes AG may collect from you, how Matrix Codes AG may process your personally identifiable information, how you can limit Matrix Codes AG use of your personally identifiable information, and your rights to obtain, modify and/or delete any personally identifiable information we have collected from you.  </p>

                <p className="subtitle">2. INFORMATION WE COLLECT</p>

                <p className="text text-item"><strong>2.1 Information you provide Matrix Codes AG</strong><br /></p>
                <p className="text">Matrix Codes AG collects personal information when you request information about our services or otherwise voluntarily provide such information through our site. </p>

                <p className="text text-item">2.2 Information Collected Automatically</p>
                <p className="text">When you use our site, we automatically collect certain information by the interaction of your mobile device or web browser with our platform. </p>

                <p className="text text-item">2.3 Cookies</p>
                <p className="text">Matrix Codes AG may use cookies, web beacons, behavioral advertising or similar technology to track your use of its website.<br /><br />
                    The website places cookies, which are small data files, on your computer or handheld device. Cookies are essential for helping us deliver a high quality website and online experience for our users, and some collect information about browsing behavior.
                </p>

                <p className="subtitle">3. HOW INFORMATION IS USED</p>

                <p className="text">When Matrix Codes AG uses or processes personal data about you, it does so only as necessary to provide the services you use or otherwise with your consent, to comply with applicable law, or to fulfill other legitimate interests of you or us as described in this Policy.  Through our site, you will be provided with the choice of which types of communications you will receive with us, and the ability to change those choices whenever you want. </p>

                <p className="text text-item"> <strong>3.1 Information Matrix Codes AG processes with your consent</strong></p>
                <p className="text">Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our site or ask us to provide you more information about our business, you provide your consent to us to process information that may be personally identifiable information.<br />
                    Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of Cookies or to receive communications from us.
                </p>

                <p className="text text-item"><strong>3.2 Legally Required Releases of Information</strong></p>
                <p className="text">Matrix Codes AG  may be legally required to disclose your personally identifiable information, if such disclosure is (a) required by law, or other legal process; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect us from legal action or claims from third parties including you; and/or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, clients, third party partners, employees, and affiliates.</p>

                <p className="subtitle">4. WHO WE SHARE YOUR INFORMATION WITH</p>

                <p className="text">In order to provide our services, we may need to provide your information to our trusted third-party partners, for example:</p>

                <p className="text"><strong>4.1 </strong>Matrix Codes AG will cooperate with all third parties to enforce their intellectual property or other rights. We will also cooperate with law enforcement requests from within or outside your country of residence.</p>

                <p className="text"><strong>4.2</strong> Matrix Codes AG shall not sell, share, hire or otherwise deal in any of your personal information with third parties unless you give us specific permission to do so when you register or through a subsequent affirmative confirmation on the Services.</p>

                <p className="subtitle">5. CONTROLLING YOUR INFORMATION</p>

                <p className="text"> <strong>5.1</strong> You may exercise any of the rights described in this section by sending an email to us. Please note that we will ask you to verify your identity before taking further action on your request. Matrix Codes AG tries to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

                <p className="text"><strong>5.2</strong> Rectification of Inaccurate or Incomplete Information. You have the right to ask us to correct inaccurate or incomplete personal information concerning you.</p>

                <p className="text"><strong>5.3</strong> Data Access and Portability. You have the right to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format and/or request us to transmit this information to another service provider (where technically feasible).</p>

                <p className="text"><strong>5.4</strong> Data Retention and Erasure. We generally retain your personal information for a period of six years after your last active use of our website (in line with our legal obligation to keep order information for this duration for tax, legal reporting and auditing obligations). If you no longer want us to keep your information, you can request that we erase your personal information. </p>

                <p className="subtitle">6. OBTAINING, CHANGING OR DELETING YOUR INFORMATION</p>

                <p className="text text-item">6.1 Access to your personal information</p>
                <p className="text">To obtain a copy of all information we maintain about you, you may send us a request using the contact information at the end of this Policy or, if available, through a tool on our site.  After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.</p>

                <p className="text text-item">6.2 Remove or Change your Information</p>
                <p className="text">If you wish us to remove or change personally identifiable information that you have provided us, you may contact us at the contact information at the end of this Policy or if available through a tool on our site. </p >

                <p className="text text-item">6.3 Verification of your Information</p>
                <p className="text">When Matrix Codes AG receives any request to access, edit or delete personally identifiable information, we will first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.</p >


                <p className="subtitle">7. RETENTION PERIOD FOR PERSONAL DATA</p>

                <p className="text">Except as otherwise mentioned in this Policy, we keep your personally identifiable information only for as long as required:</p>

                <p className="text text-list"><span className="marker" />to provide you with the services you have requested, or otherwise to perform or enforce a contract between us;<br /></p>
                <p className="text text-list"><span className="marker" />to continue to provide the best possible user experience to visitors who return to our site to collect information;<br /></p>
                <p className="text text-list"><span className="marker" />to comply with other law, including for any period demanded by tax authorities; or<br /></p>
                <p className="text text-list"><span className="marker" />to support a claim or defense in any court or in any legal, regulatory or administrative proceeding.</p><br />

                <p className="subtitle">8. LIMITATION OF LIABILITY</p>

                <p className="text">You assume the sole risk of transmitting your information as it relates to the use of this site, and for any data corruptions, intentional interceptions, intrusions or unauthorized access to information, or of any delays, interruptions to or failures preventing the use this site. In no event shall we (the Findmyresearch.com team) be liable for any direct, indirect, special, consequential or monetary damages, including fees, and penalties in connection with your use of materials posted on this site or connectivity to or from this site to any other site.</p>

                <p className="subtitle">9. PROTECTING YOUR CHILD’S PRIVACY</p>

                <p className="text">Even though our site is not designed for use by anyone under the age of 18, we realize that a child may attempt to access the site. Matrix Codes AG does not knowingly collect personally identifiable information from a child. If you are a parent or guardian and believe your child is using our site, please contact us. Matrix Codes AG may ask for proof of identification before we remove any information to prevent malicious removal of information. If we discover on our own that a child is accessing our site, we will delete the information as soon as we discover it, we will not use the information for any purpose, and we will not disclose the information to third parties. </p>

                <p className="subtitle">10. CHANGES TO OUR PRIVACY POLICY</p>

                <p className="text">Matrix Codes AG reserves the right to change this privacy policy at any time. If we decide to change this Privacy Policy, we will post those changes on the site so our users are always aware of what information we collect, use, and disclose. </p>

            </div >
        </PageTemplate >
    )
}

export default PrivacyPolicy;