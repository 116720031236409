import React, { useEffect } from 'react';

import Projects from '../api/Projects';

const AcceptUser = () => {
    useEffect(() => {
        acceptApplicant();
    }, [])

    const acceptApplicant = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const userId = urlParams.get('userId');
        const projectId = urlParams.get('projectId');
        const token = urlParams.get('token');
        await Projects.acceptApplicantEmail(userId!, projectId!, token!);
    }

    return (
        <div id="accept-user">
           
        </div>
    )
}

export default AcceptUser;