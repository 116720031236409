import React, { useState, useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HomeCard from './HomeCard';
import { CircularProgress } from '@mui/material';

import Projects from '../../../api/Projects';
import IProjectItem from '../../../interfaces/IProjectItem';
import IProjects from '../../../interfaces/IProjects';
import "../../../assets/css/HomeProjects.css";

const HomeProjects = () => {
    const [listProjects, setListProjects] = useState<IProjects>();
    const [loading, setLoading] = useState<boolean>(true);

    const fetchProjects = async () => {
        const projectsItems = await Projects.findFeatured(setLoading);
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;

        if (localStorage.getItem("@fmr/userLogged") == "true") {
            const filterFavorites = { page: 1, limit: 999 };
            const favoritesItems = await Projects.findFavorites(setLoading, authorization, filterFavorites);
            projectsItems.map((project: any) => {
                favoritesItems.favoritedProjects.map((favorite: any) => {
                    if (project._id === favorite._id) {
                        const index = projectsItems.findIndex((element: any) => element._id == favorite._id);
                        projectsItems[index]["isFavorite"] = true;
                    }
                });
            });
        }

        projectsItems.length == 0 ? setListProjects({ projects: undefined }) : setListProjects({ projects: projectsItems });
    };

    useEffect(() => {
        fetchProjects();
    }, [])

    const itemsProjects = () => {
        return listProjects?.projects?.map((project: IProjectItem, index) => {
            if (index < 4) {
                return <HomeCard
                    isFavorite={project.isFavorite}
                    acceptedApplicants={project.acceptedApplicants.length}
                    projectTitle={project.projectTitle}
                    numberOfStudents={project.numberOfStudents}
                    maxNumberOfStudents={project.maxNumberOfStudents}
                    projectDescription={project.projectDescription}
                    createdAt={project.createdAt}
                    _id={project._id}
                    speciality={project.speciality}
                    typeOfResearch={project.typeOfResearch}
                    school={project.school}
                />;
            }
        })
    }

    return (
        <div id="home-projects">
            <div className="content-projects">
                <h3 className="title-projects">Recently posted projects</h3>
                <div className="project-list">
                    {loading ?
                        <CircularProgress className="icon-loading" />
                        :
                        itemsProjects()
                    }
                </div>
                <a href="/projects" className="see-projects">See more projects<ArrowForwardIcon className="see-icon" /></a>
            </div>
        </div>
    )
}

export default HomeProjects;