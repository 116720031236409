import React from 'react';

import PageTemplate from '../components/PageTemplate';

const ContactUsPage = () => {
    return (
        <PageTemplate title="Contact Us">
            <div id="terms-content">
                <p className="text"><strong>If you have any questions about the platform or if you have any issues/concerns with your account - please visit our FAQ page, or contact us at <a href="mailto:helpdesk@findmyresearch.com">helpdesk@findmyresearch.com</a><br /><br />

                    We're always looking to improve our site, and we'd love to hear your thoughts! Please send it to <a href="mailto:feedback@findmyresearch.com">feedback@findmyresearch.com</a>.<br /><br />

                    If a project owner is not responding back to your contact request despite the project status being 'active', please email <a href="mailto:communication@findmyresearch.com">communication@findmyresearch.com</a>. Please include the project title and institution.<br /><br />

                    For partnership or business inquiries, please reach out to <a href="mailto:admin@findmyresearch.com">admin@findmyresearch.com</a></strong></p>
            </div>
        </PageTemplate>
    )
}

export default ContactUsPage;