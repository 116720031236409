import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import '../assets/css/FAQPage.css';

const ResearchResourcesPage = () => {
    return (
        <div id="faq-content">
            <div className="content-page">
                <h1 className="faq-title">Research Paper Writing Resources</h1>

                <div className="text-box">
                    <p className="subtitle"> General Overview</p>

                    <p className="text"><i>Writing a paper, especially if it is your first time, can be daunting. This is a quick overview on how to write specific types of papers to help make this process easier.</i></p>

                    <p className="text spacer"><span className="marker" />First and foremost - read through this, and then look at examples on PubMed (https://pubmed.ncbi.nlm.nih.gov/) of the type of paper you’re writing. If you are able to find a paper or two similar to your topic, even better! Papers of similar topics give you an idea of what points to get across, what journal reviewers are looking for in your manuscript, and can also give you additional articles to review from perusing the references. </p>

                    <p className="text spacer"><span className="marker" />Use the information outlined here to then write up your paper once you have the information you need.</p>

                    <p className="text spacer"><span className="marker" />We’ll define the most common types of research papers that medical students encounter and suggestions on how to write them up. </p>

                    <p className="text spacer"><span className="marker" />Remember - this is just information to help write things up. There is no absolute right way to write up any of the below, but these are to help you get started!</p>
                </div>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What is a Case Report?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">A case report is a detailed report of a single patient encounter. It usually is an interesting or uncommon diagnosis or approach to treatment that you are reporting on.</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">What is a Case Series?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text"> A case series is a detailed report of multiple patient encounters. </p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">How do I write a case report?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">The basic outline of a standard case report:</p>

                        <p className="text">Abstract: This is a quick summary of your case conveying the overall message & salient points. You can think of the abstract as a mini summary of all subparts. 1-2 sentences of intro, 1-2 sentences of case background, 1-2 sentences on discussion and conclusion of why this is interesting. Usually there are certain word/character limitations of the abstract that journals will have.</p>

                        <p className="text">Introduction: Discuss the topic of your case report, and then discuss the uniqueness of your case and why it is different compared to everything else that is already out there. Some journals will not require an introduction for a case report and instead have you jump straight into discussing the case. Look at other cases published in the journal you are submitting to for an idea of what has worked well in the past.</p>

                        <p className="text">Case: Discuss the case itself - start with the clinical findings (a basic HPI), significant work-up and interventions, radiological/pathological findings, and treatment plans. Make sure you discuss the case outcome and follow up. This is always a nice place to add in images of radiographic studies performed and surgical/clinical photos of the case, where applicable.</p>

                        <p className="text">Discussion: Discuss why your case is unique or different. You will also do a quick review of the literature on PubMed or Google Scholar, and summarize other information briefly in the literature - and discuss if there is any supporting or opposing views of your case. </p>

                        <p className="text">Conclusion: Summarize your case in a few points, but also potentially talk about overall teaching points learned from your case.</p>

                        <p className="text">For more detail, we advise you to use the <strong>CARE Checklist,</strong> <a href="https://www.care-statement.org/checklist" target="_blank">https://www.care-statement.org/checklist</a></p>

                        <p className="text">Here is a quick example of a case report: <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8977124/" target="_blank">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8977124/</a></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle"> What is an original research article? </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">An original research article describes an experimental study that was performed. This the kind of paper you write when you’ve done a specific experimental study (i.e. prospective double blinded studies) and you’re now writing the paper about your specific findings. These are usually the cream of the crop in terms of research, as they are usually original research (obviously) and considered a ‘primary source’ in the literature. </p>

                        <p className="text spacer"><span className="marker" />The basic outline includes Introduction, Methods, Results, Discussion, and Conclusion. If you’re writing up one of these, we strongly advise you to use the PRISMA checklist (<a href="http://www.prisma-statement.org" target="_blank">http://www.prisma-statement.org</a>). </p>

                        <p className="text spacer"><span className="marker" />Head down to the ‘how to write a traditional/narrative review’ and the ‘How do I convert a Case Report to a Review article/literature review’ sections for some ideas about what your introduction and conclusion should include. Or, go to the first resource at the bottom in ‘other helpful resources’. </p>

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle"> What is a literature review?</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">A Literature review discusses a particular topic or answers a specific question. It’s like you’re teaching a quick class on the topic.</p>

                        <p className="text">There are many types of literature reviews, but the most common types are Traditional/Narrative Review, Systemic Review, Meta-Analysis Review, or Original Research Articles. Most literature reviews are written as traditional/narrative reviews, and this is likely what you will be writing unless it is specified to be a systematic review or a meta-analysis. </p>

                        <p className="text">A Traditional/Narrative review summarizes a body of literature. The purpose is to write a comprehensive review of a topic, write about new research ideas or identify gaps of knowledge - but it does not require a complete collection of all existing evidence of a topic, or require any quantitative methods/statistical analysis. The structure varies on the topic in question so talk to your PI if you are stuck.</p>

                        <p className="text">A Systematic Review is a summary of multiple studies.  The purpose is to collect all existing evidence on a specific topic to answer a specific research question. This requires a deep dive into the PubMed search bar, so it helps to be familiar with how to effectively search.</p>

                        <p className="text">A Meta-Analysis is a Systematic Review that uses statistical analysis to synthesize all the results in the literature. The purpose is to collect all empirical research by using a defined systematic method (including statistical analysis, etc). There is “power” in numbers!</p>

                        <p className="text">**Traditional reviews and original research papers are easier to write as they do not require exhaustive literature searches or statistical analyses**</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">How do I write a Traditional/Narrative review? (see ‘how to convert a case report to a lit review’ for further details)</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">First - find a topic of the lit review you are looking to do. Next, go to PubMed and really delve into the literature about the topic. Find as much information as you can about the topic from as many different resources as you can and put together the information in an easy to follow yet informative way. Look for themes as you are researching and these will naturally fall into categories or “section headers”.  Make sure that you use different resources, but also make sure you reference each fact you use (don’t forget to remember what paper you got every factoid of information from - you must reference them all in your bibliography and within the manuscript using in text citations).</p>

                        <p className="text">The basic outline:</p>

                        <p className="text">Abstract – Again - write a summary of your literature review with the most pertinent information. Why did you write this paper? What is your overall message? Most writers save this section for last. </p>

                        <p className="text">Introduction: Why is your topic important? It should define the purpose of the work and its significance. Explain the big picture. We usually discuss epidemiology, prevalence, and impact here.</p>

                        <p className="text">Body: This is where you’ll write out your paper. We’d recommend starting with an outline of how you’ll make your paper into a ‘story’ about your topic. Connect all your paragraphs in a systematic way. Point out and address controversies in the field. </p>

                        <p className="text">Discussion: Who is your audience? What was unique about your paper? Discuss ideas for future research pertaining to your topic. Discuss opposing views. Occasionally for narrative reviews, the body and discussion are unified as one because you are marching your way through the different facets of the topic and discussing relevant literature throughout</p>

                        <p className="text">Conclusion: Summarize your findings and your main points. </p>

                        <p className="text">Here is a great resource to read: <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3715443/" target="_blank">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3715443/</a></p>

                        <p className="text">Here is an example: <a href="https://www.mdpi.com/2673-4389/2/2/6" target="_blank">https://www.mdpi.com/2673-4389/2/2/6</a></p>

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">How do I convert a Case Report to a Review article/literature review?</p> </AccordionSummary>
                    <AccordionDetails>
                        <p className="text">We will use one of our cases as an example</p>

                        <p className="text">Hypermanganesemia causing Dystonia in patients with chronic liver disease <a href="https://journals.lww.com/ajg/Fulltext/2021/10001/S2810_Metallic_Elements,_Everywhere__A_Case_of.2814.aspx" target="_blank">https://journals.lww.com/ajg/Fulltext/2021/10001/S2810_Metallic_Elements,_Everywhere__A_Case_of.2814.aspx</a></p>

                        <p className="text spacer"><span className="marker" />Find the case report/topic you are interested in</p>

                        <p className="text spacer"><span className="marker" />Use resources such as PubMed (best/easiest one), Medline, Embase, Google Scholar, or textbooks to learn about the topic. </p>

                        <p className="text spacerTwo"><span className="marker" />See what papers have already been published about the topic specifically. For example, PubMed search about ‘dystonia and chronic liver disease’, or ‘hypermanganesemia and dystonia’, or ‘dystonia and chronic liver disease’. Again, this is where great familiarity with search bar rules/tips comes in handy.</p>

                        <p className="text spacerTwo"><span className="marker" />Really get to know the topic. Use the information in these papers (in particular the introductions and the discussions) to help form your paper as well.</p>

                        <p className="text spacer"><span className="marker" />Once you have a strong background on the topic, try to summarize the literature that you have found. Then your review article can be a resource for those learning about this topic. It could also try to summarize new interventions.</p>

                        <p className="text spacerTwo"><span className="marker" />For example, the paper could be:</p>

                        <p className="text spacerTrhee"><span className="marker" />A review paper that will discuss the prevalence and the pathophysiology of liver disease and how it absorbs manganese</p>

                        <p className="text spacerTwo"><span className="marker" />A review paper discusses new emerging treatment options/interventions</p>

                        <p className="text spacer"><span className="marker" />Go ahead and summarize all the papers. Take notes about the different aspects of the topic.</p>

                        <p className="text spacerTwo"><span className="marker" />One strategy is to start making headings about topics. Come up with four or five main points/headings about the topic, and then come up with a few points about these headings that you want to make. </p>

                        <p className="text spacerTwo"><span className="marker" />For example - one heading could be ‘the physical manifestations of chronic liver disease’. The next, ‘physical manifestations of hypermanganesemia’. The next heading could be ‘treatment of dystonia due to hypermanganesemia’. Next heading can be ‘treatment of hypermanganesemia in chronic liver disease’. The idea is to summarize your specific topic with all the information that you can find in the literature about each of the topics. </p>

                        <p className="text spacerTwo"><span className="marker" />The great thing is there is no standard protocol - this is your paper, and as long as you have the data, you can include the information (ensuring that you reference it.)</p>

                        <p className="text spacerTwo"><span className="marker" /><strong>That being said, make sure you save the papers and the facts you are referencing </strong> so you can refer back to them. Every point you make, you’ll need a reference for it (that’s the number you see next to sentences in the papers). </p>

                        <p className="text spacer"><span className="marker" />Now, turn it into the lit review!</p>

                        <p className="text spacer"><span className="marker" />Start with the introduction and write about the topics themselves (usually talk about the specifics - for example, talk about chronic liver disease and its prevalence, then why hypermanganesemia is important, why it can cause dystonia, and the overall relationship of them all.)</p>

                        <p className="text spacer"><span className="marker" />Then, the body. You’ll basically take what you did in step 4, but make it flow - make it into a story. </p>

                        <p className="text spacer"><span className="marker" />Finally, write your conclusion. </p>

                        <p className="text">Here is an example of a lit review: <a href="https://www.mdpi.com/2673-4389/2/2/6/htm" target="_blank">https://www.mdpi.com/2673-4389/2/2/6/htm</a></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className="subtitle">Other helpful resources:</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="text spacer"><span className="marker" />General steps to writing up a manuscript (describing intro, methods, results, discussion, conclusion, references, tables/figures.)</p>

                        <p className="text spacer"><span className="marker" /><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3626472/" target="_blank">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3626472/</a></p>

                        <p className="text spacerTrhee"> <span className="marker" />Go to second half of article starting at ‘writing the manuscript’ where it discusses this in detail. </p>

                        <p className="text spacer"><span className="marker" />How to write a case report</p>

                        <p className="text spacerTwo"> <span className="marker" /><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4175810/" target="_blank">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4175810/</a></p>

                        <p className="text spacerTrhee"><span className="marker" />Tips for writing up a case report for the novice author</p>

                        <p className="text spacerTwo"> <span className="marker" /> <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5686928/" target="_blank">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5686928/</a></p>

                        <p className="text spacerTrhee"><span className="marker" />Brief overview of how to write up a case report</p>

                        <p className="text spacer"><span className="marker" />How to write up a review article, / lit review from UNC and USC</p>

                        <p className="text spacerTwo"> <span className="marker" /> <a href="https://writingcenter.unc.edu/tips-and-tools/literature-reviews/" target="_blank">https://writingcenter.unc.edu/tips-and-tools/literature-reviews/</a></p>

                        <p className="text spacerTrhee"><span className="marker" />The basics on writing up a literature review</p>

                        <p className="text spacerTwo"> <span className="marker" /> <a href="https://libguides.usc.edu/healthsciences/spc" target="_blank">https://libguides.usc.edu/healthsciences/spc</a></p>

                        <p className="text spacerTrhee"><span className="marker" />i.Lit review resources</p >
                    </AccordionDetails>
                </Accordion>

                <div className="text-box">
                    <p className="subtitle">Finally:</p>

                    <p className="text">If you think you have a target journal / publisher (or your PI has a target journal) - MAKE SURE you are looking at the individual journal’s guidelines. This is because every journal/publisher is different and may have different rules, word count requirements, figure limitations, and other unique requirements that should be known. We strongly recommend that you read their guidelines.</p>

                    <p className="text">ALSO REMEMBER: Red means love. When you finish your paper and send your beautifully crafted manuscript to your team for edits, you should be happy if it comes back littered with red (or whatever other color) edits on track changes. It means that your PI/team cares and is invested to help. </p>
                </div>
            </div >
        </div >
    )
}

export default ResearchResourcesPage; 