import axios from 'axios';
import Swal from 'sweetalert2'

const { REACT_APP_API_URL } = process.env;

export const getRichText = async () => {
    const { data } = await axios.get(`${REACT_APP_API_URL}/richtext/`);
    return data;
}

export const updateRichText = async (richtext: any) => {
  console.log(richtext);
    const { data } = await axios.patch(`${REACT_APP_API_URL}/richtext/`, {
        richText: {
          content: richtext,
        },
    });
    await Swal.fire({
      title: 'Success',
      text: 'Rich text updated',
      icon: 'success',
      confirmButtonText: 'OK'
    })
    return data;
}