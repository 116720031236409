import React, { useState, useEffect } from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import statesList from '../../utils/StatesList.json';
import specialityList from '../../utils/SpecialityList.json';
import NoProjectsFound from '../../components/ProjectList/NoProjectsFound';
import IFilterProjects from '../../interfaces/IFilterProjects';
import IProjects from '../../interfaces/IProjects';
import IProjectItem from '../../interfaces/IProjectItem';
import IStateItem from '../../interfaces/IStateItem';
import Projects from '../../api/Projects';
import ProjectCard from '../../components/admin/ProjectCard';
import '../../assets/css/Admin.css';

const ProjectList = () => {
    const [listProjects, setListProjects] = useState<IProjects>();
    const [loading, setLoading] = useState<boolean>(true);
    const [filterParams, setFilterParams] = useState<Partial<IFilterProjects>>({
        orderBy: "Newest",
        limit: 25,
    });
    const [listStatesCheck, setListStatesCheck] = useState<Array<string>>([]);
    const [region, setRegion] = useState<string>();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selectType, setSelectType] = useState<string>();
    const [showTypeInput, setShowTypeInput] = useState<boolean>(false);

    const fetchProjects = async () => {
        const projectsItems = await Projects.findAllAdmin(setLoading, filterParams);
        projectsItems.projects.length == 0 ? setListProjects({ projects: undefined }) : setListProjects({ projects: projectsItems.projects });
        setTotalPages(projectsItems.pages);
        console.log(projectsItems);
    };

    useEffect(() => {
        fetchProjects();
    }, [filterParams.page, filterParams.limit, filterParams.orderBy])

    const itemsProjects = () => {
        if (listProjects?.projects == undefined) {
            return <NoProjectsFound message="No projects projects found" />
        } else {
            return listProjects?.projects?.map((project: IProjectItem) => {
                return <ProjectCard
                    title={project.projectTitle}
                    status={project.status}
                    createdAt={project.createdAt}
                    type={project.typeOfResearch}
                    speciality={project.speciality}
                    _id={project._id}
                    isActive={project.isAccepted}
                    acceptedApplicants={project.acceptedApplicants}
                    projectDescription={project.projectDescription}
                    maxNumberOfStudents={project.maxNumberOfStudents}
                    numberOfStudents={project.numberOfStudents}
                    state={project.state}
                    email={project.email}
                />;
            })
        }
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setFilterParams({ ...filterParams, page: value });
    };

    const handleRegion = (region: string) => {
        setListStatesCheck([]);
        setRegion(region);
    }

    const handleStates = (name: string) => {
        if (listStatesCheck.includes(name)) {
            setListStatesCheck(listStatesCheck.filter(item => item !== name));
        } else {
            setListStatesCheck([...listStatesCheck, name]);
        }
    }

    const handleType = () => {
        if (selectType == "Other") {
            setShowTypeInput(true);
            setFilterParams({ ...filterParams, typeOfResearch: undefined });
        } else {
            setShowTypeInput(false);
            setFilterParams({ ...filterParams, typeOfResearch: selectType });
        };
    }

    useEffect(() => {
        handleType();
    }, [selectType])

    return (
        <div className="content-admin">
            <h1 className="admin-title">Project List</h1>
            <div className="admin-header">
                <div className="filters-projects">
                    <FormControl className="item-filter" fullWidth>
                        <InputLabel>Speciality</InputLabel>
                        <Select
                            label="Speciality"
                            value={filterParams.speciality || ''}
                            onChange={e => setFilterParams({
                                ...filterParams,
                                speciality: e.target.value
                            })}
                        >
                            {specialityList?.specialities?.map((speciality: string) => {
                                return <MenuItem value={speciality}>{speciality}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className="item-filter" fullWidth>
                        <InputLabel>Region</InputLabel>
                        <Select
                            label="Region"
                            value={region}
                            onChange={(e) => (handleRegion(e.target.value))}
                        >
                            <MenuItem value="West">West</MenuItem>
                            <MenuItem value="Midwest">Midwest</MenuItem>
                            <MenuItem value="Southeast">Southeast</MenuItem>
                            <MenuItem value="Northeast">Northeast</MenuItem>
                        </Select>
                    </FormControl>

                    <Accordion className="item-filter state-filter">
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="subtitle">State</p>
                        </AccordionSummary>
                        <AccordionDetails className="states-list">
                            {statesList?.states?.map((state: IStateItem, index) => {
                                return <FormControlLabel className="state-item" control={<Checkbox checked={listStatesCheck.includes(state.name)} onClick={() => (handleStates(state.name))} />} label={state.name} />
                            })}
                        </AccordionDetails>
                    </Accordion>

                    <FormControl className="item-filter" fullWidth>
                        <InputLabel>Type of Research Project</InputLabel>
                        <Select
                            label="Type of Research Project"
                            value={selectType || ''}
                            onChange={(e) => setSelectType(e.target.value)}
                        >
                            <MenuItem value="Case Report/Case Series">Case Report/Case Series</MenuItem>
                            <MenuItem value="Literature review">Literature review</MenuItem>
                            <MenuItem value="Original research paper">Original research paper</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>

                    {showTypeInput &&
                        <FormControl className="item-filter" fullWidth>
                            <TextField
                                required
                                id="outlined"
                                type="text"
                                label="Other type"
                                value={filterParams.typeOfResearch || ''}
                                onChange={e => setFilterParams({
                                    ...filterParams,
                                    typeOfResearch: e.target.value
                                })}
                            />
                        </FormControl>
                    }
                    <div className="sort-projects">
                        <FormControl className="item-show">
                            <InputLabel>Show</InputLabel>
                            <Select
                                label="Show"
                                value={filterParams.limit || ''}
                                onChange={e => setFilterParams({
                                    ...filterParams,
                                    limit: e.target.value as number
                                })}
                            >
                                <MenuItem value={25}>25 Projects</MenuItem>
                                <MenuItem value={50}>50 Projects</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="item-sort">
                            <InputLabel>Sort</InputLabel>
                            <Select
                                label="Sort"
                                value={filterParams.orderBy || ''}
                                onChange={e => setFilterParams({
                                    ...filterParams,
                                    orderBy: e.target.value
                                })}
                            >
                                <MenuItem value="Oldest">Oldest</MenuItem>
                                <MenuItem value="Newest">Newest</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button onClick={fetchProjects} className="btn-filter" fullWidth variant="contained">search</Button>
                </div>
            </div>
            <div id="user-list">
                {loading ? <CircularProgress className="loading-list-admin" /> : (
                    listProjects?.projects == undefined ?
                        <NoProjectsFound message="No projects projects found" />
                        :
                        <table className="admin-table" >
                            <tr className="table-header">
                                <th className="header-item header-btn" />
                                <th className="header-item">Title</th>
                                <th className="header-item">Status</th>
                                <th className="header-item">Type</th>
                                <th className="header-item">Speciality</th>
                                <th className="header-item">Days</th>
                                <th className="header-item header-btn" />
                                <th className="header-item header-btn" />
                            </tr>
                            {itemsProjects()}
                        </table>
                )
                }
                {listProjects?.projects == undefined ?
                    <span /> :
                    <Pagination
                        className="admin-pagination"
                        page={filterParams.page || 1}
                        onChange={handlePageChange}
                        count={totalPages}
                    />
                }
            </div>
        </div>
    )
}

export default ProjectList;