import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './views/App';
import Admin from './views/Admin';

ReactDOM.render(
  <React.StrictMode>
    {window.location.pathname.includes("/admin") ? <Admin /> : <App />}
  </React.StrictMode>,
  document.getElementById('root')
);
