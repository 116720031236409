import React from 'react';

import PageTemplate from '../components/PageTemplate';

const LegalDisclaimerPage = () => {
    return (
        <PageTemplate title="LEGAL DISCLAIMER">
            <div id="privacy-content">
                <p className="subtitle">1. NO WARRANTY </p>
                <p className="text"><strong>1.1</strong>Please note that this is Findmyresearch.com disclaimer of legal liability for the quality or reliability of the services.  </p>
                <p className="text"><strong>1.2</strong>Findmyresearch.com makes no representation or warranty about the services, including any representation that the services will be uninterrupted or error-free and provide the services (including content and information) on “as is” and “as available” basis. To the fullest extent permitted under applicable law, we disclaim any implied or statutory warranty, including any implied or statutory warranty, including any implied warranty of title, accuracy of data, non-infringement, merchantability or fitness for a particular purpose. </p>

                <p className="subtitle">2. EXCLUSION OF LIABILITY</p>
                <p className="text"><strong>2.1</strong>Please note that these are the limits of legal liability Findmyresearch.com may have to you. </p>
                <p className="text"><strong>2.2</strong>To the fullest extent permitted by law, Findmyresearch.com will not be liable in connection with this agreement for any loss or damage or any indirect, incidental, consequential, special or punitive damages. </p>
                <p className="text"><strong>2.3</strong>Findmyresearch.com will not be liable to you in connection with this agreement. </p>

                <p className="subtitle">3. DISCLAIMER AND LINKS TO THIRD-PARTY SITES</p>
                <p className="text"><strong>3.1</strong>The site may contain information provided to Findmyresearch.com by third parties. This information is provided on the site for your convenience only and Findmyresearch.com makes no warranties or representations as to the accuracy, reliability or completeness of such information. </p>
                <p className="text"><strong>3.2</strong>The site also may contain links to other third-party sites, which may offer other services. These links are provided for your convenience only. Findmyresearch.com has no responsibility for these third party websites or content contained therein. </p>

                <p className="subtitle">4. BASIS OF THE BARGAIN; EXCLUSIONS </p>
                <p className="text">The Limitations of Liability contained herein are part of the basis of the bargain between you and us and shall apply to all claims of liability (e.g., tort, contract, negligence and warranty) even if we have been told of the possibility of any such damage and even if these remedies fail their essential purpose. </p>

                <p className="subtitle">5. SEVERABILITY</p>
                <p className="text">In the event any provision or part of this Disclaimer is found to be invalid or unenforceable, only that particular provision or part so found, and not the entire Disclaimer, will be inoperative.</p>
            </div >
        </PageTemplate >
    )
}

export default LegalDisclaimerPage;