import React from 'react';

import PageTemplate from '../components/PageTemplate';

const AboutUsPage = () => {
    return (
        <PageTemplate title="About Us">
            <div id="terms-content">
                <p className="text">This platform was created by Adnan Khan and Majdee Islam. Adnan is a recently graduated internal medicine resident and GI hospitalist, and Maj is a urology fellow.</p>
                <p className="text">The idea of this platform has been years in the making. We found that throughout medical school and residency/fellowship, there were numerous medical/pre-med students who showed extreme interest in getting involved with clinical research. However, it was often difficult for them to find research projects and research mentors due to many different factors (including minimal research opportunities nearby, lack of access to research opportunities, or busy researchers/mentors). In addition, after the pandemic we discovered that many students were starting to look for research opportunities from other institutions, and had success with being able to complete certain types of research projects remotely (including visiting/away-rotation students). This started to get us thinking about a centralized research resource for students to use - which students stated would be a valuable resource.</p>
                <p className="text">We then interviewed researchers themselves across the country, many of whom expressed that they had many project ideas or actual projects that were not being completed due to not having enough time to perform the projects themselves (usually because of their own clinical duties). The researchers expressed significant interest in having students assist them with their research not only to be more productive from a research standpoint, but also to be able to mentor students and foster education and goodwill. Some interest was also expressed in having a resource that could invite inter-institutional collaborations, and also promote the potential for global medicine.</p>
                <p className="text">Therefore, after much deliberation and planning we created this platform with the intention of having an easily accessible centralized resource for both students and researchers to both find and post research opportunities - and use this to further research, innovation, mentorship, and collaboration in medicine.</p>
            </div>
        </PageTemplate>
    )
}

export default AboutUsPage;