import axios from 'axios';
import Swal from 'sweetalert2'
import ISponsor from '../interfaces/ISponsor';

const { REACT_APP_API_URL } = process.env;
const url = `${REACT_APP_API_URL}/sponsor`;

const findAll = async (setLoading: Function): Promise<ISponsor[] | null> => {
  setLoading(true);
  try {
    const response = await axios({
      method: 'get',
      url
    })
    console.log(response.data)
    setLoading(false)
    return response.data
  } catch (error) {
    console.log(error);
    return null;
  }
}

const toggleHyperLink = async (sponsor: ISponsor): Promise<ISponsor | null> => {
  try {
    const response = await axios({
      method: 'patch',
      url: `${url}/${sponsor._id}`,
      data: {
        hyperlink: !sponsor.hyperlink
      }
    })
    Swal.fire({
      title: 'Success:',
      text: `Hyperlink ${!sponsor.hyperlink ? 'activated' : 'deactivated'}.`,
      icon: `${!sponsor.hyperlink ? 'success' : 'info'}`,
      confirmButtonText: 'OK',
      confirmButtonColor: "#1976d2"
    });
    return response.data
  } catch (error) {
    console.log(error);
    return null;
  }
}

const toggleSecondLineText = async (sponsor: ISponsor): Promise<ISponsor | null> => {
  try {
    const response = await axios({
      method: 'patch',
      url: `${url}/${sponsor._id}`,
      data: {
        secondLine: !sponsor.secondLine
      }
    })
    Swal.fire({
      title: 'Success:',
      text: `Second Line ${!sponsor.secondLine ? 'activated' : 'deactivated'}.`,
      icon: `${!sponsor.secondLine ? 'success' : 'info'}`,
      confirmButtonText: 'OK',
      confirmButtonColor: "#1976d2"
    });
    return response.data
  } catch (error) {
    console.log(error);
    return null;
  }
}

const toggleActivation = async (id: string, activation: boolean): Promise<ISponsor | null> => {
  try {
    const response = await axios({
      method: 'patch',
      url: `${url}/${id}`,
      data: {
        activated: activation
      }
    })
    Swal.fire({
      title: 'Success:',
      text: `Text ${activation ? 'activated' : 'deactivated'}.`,
      icon: `${activation ? 'success' : 'info'}`,
      confirmButtonText: 'OK',
      confirmButtonColor: "#1976d2"
    });
    return response.data
  } catch (error) {
    console.log(error);
    return null;
  }
}

const changeText = async (id: string, text: string, hyperlink?: boolean): Promise<ISponsor | null> => {
  try {
    let data;
    if (hyperlink) {
      data = {
        link: text
      }
    } else {
      data = {
        text
      }
    }
    const response = await axios({
      method: 'patch',
      url: `${url}/${id}`,
      data
    })
    Swal.fire({
      title: 'Success:',
      text: `Text changed!`,
      icon: 'success',
      confirmButtonText: 'OK',
      confirmButtonColor: "#1976d2"
    });
    return response.data
  } catch (error) {
    console.log(error);
    return null;
  }
}

const changeSecondaryText = async (id: string, text: string, hyperlink?: boolean): Promise<ISponsor | null> => {
  try {
    let data;
    if (hyperlink) {
      data = {
        secondaryLink: text
      }
    } else {
      data = {
        secondaryText: text
      }
    }
    const response = await axios({
      method: 'patch',
      url: `${url}/${id}`,
      data
    })
    Swal.fire({
      title: 'Success:',
      text: `Secondary Text changed!`,
      icon: 'success',
      confirmButtonText: 'OK',
      confirmButtonColor: "#1976d2"
    });
    return response.data
  } catch (error) {
    console.log(error);
    return null;
  }
}

export default {
  findAll,
  toggleHyperLink,
  toggleActivation,
  changeText,
  toggleSecondLineText,
  changeSecondaryText
}