import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

import User from "../../api/User";
import IUser from '../../interfaces/IUser';
import Logo from '../../assets/images/header-logo.svg';
import "../../assets/css/LoginAdmin.css";

const LoginAdmin = () => {
    const [data, setData] = useState<Partial<IUser>>({});
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();
        User.loginAdmin(data, setLoading, setErrorPassword);
    }

    return (
        <div id="login-admin">
            <div className="content-login">
                <div className="box-logo">
                    <img src={Logo} alt="Logo" />
                </div>
                <span className="divider" />
                <form className="container-login" onSubmit={handleLogin}>
                    <h1 className="title-form">Log in</h1>
                    <TextField
                        required
                        id="outlined"
                        label="Email address"
                        className="email-field"
                        value={data.email || ''}
                        onChange={e => setData({
                            ...data,
                            email: e.target.value
                        })}
                    />
                    <TextField
                        required
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        error={errorPassword}
                        helperText={errorPassword ? "Incorrect password." : ""}
                        value={data.password || ''}
                        onChange={e => setData({
                            ...data,
                            password: e.target.value
                        })}
                    />
                    <Button type="submit" className="form-btn login-btn" variant="contained">
                        {!loading ? 'Log in' : <CircularProgress color="inherit" />}
                    </Button>
                </form>
            </div>
        </div>
    )
}

export default LoginAdmin;