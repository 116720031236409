import React, { useState, useEffect } from 'react';

import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';

import NoProjectsFound from '../../components/ProjectList/NoProjectsFound';
import IFilterProjects from '../../interfaces/IFilterProjects';
import IProjects from '../../interfaces/IProjects';
import IProjectItem from '../../interfaces/IProjectItem';
import Projects from '../../api/Projects';
import ProjectCard from '../../components/admin/ProjectCard';
import '../../assets/css/Admin.css';

const PendingProjects = () => {
    const [listProjects, setListProjects] = useState<IProjects>();
    const [loading, setLoading] = useState<boolean>(true);
    const [filterParams, setFilterParams] = useState<Partial<IFilterProjects>>({
        limit: 25,
    });
    const [totalPages, setTotalPages] = useState<number>(0);

    const fetchProjects = async () => {
        const authorization = localStorage.getItem("@fmr/adminAuthorization")!;
        const projectsItems = await Projects.findPending(setLoading, authorization, filterParams);
        projectsItems.projects.length == 0 ? setListProjects({ projects: undefined }) : setListProjects({ projects: projectsItems.projects });
        setTotalPages(projectsItems.pages);
    };

    const removeSingleProject = (id: string) => {
        const newList = listProjects?.projects?.filter((project: IProjectItem) => project._id !== id);
        setListProjects({ projects: newList });
    };

    useEffect(() => {
        fetchProjects();
    }, [filterParams.page])

    const itemsProjects = () => {
        if (listProjects?.projects == undefined || listProjects.projects.length === 0) {
            return <NoProjectsFound message="No projects projects found" />
        } else {
            return listProjects?.projects?.map((project: IProjectItem) => {
                return <ProjectCard
                    title={project.projectTitle}
                    status={project.status}
                    createdAt={project.createdAt}
                    type={project.typeOfResearch}
                    speciality={project.speciality}
                    _id={project._id}
                    isActive={project.isAccepted}
                    acceptedApplicants={project.acceptedApplicants}
                    projectDescription={project.projectDescription}
                    maxNumberOfStudents={project.maxNumberOfStudents}
                    numberOfStudents={project.numberOfStudents}
                    state={project.state}
                    removeSingleProject={removeSingleProject}
                />;
            })
        }
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setFilterParams({ ...filterParams, page: value });
    };

    return (
        <div className="content-admin">
            <h1 className="admin-title">Projects pending approval</h1>
            <div className="admin-header">
            </div>
            <div id="user-list">
                {loading ? <CircularProgress className="loading-list-admin" /> : (
                    listProjects?.projects == undefined ?
                        <NoProjectsFound message="No projects projects found" />
                        :
                        <table className="admin-table" >
                            <tr className="table-header">
                                <th className="header-item header-btn" />
                                <th className="header-item">Title</th>
                                <th className="header-item">Status</th>
                                <th className="header-item">Type</th>
                                <th className="header-item">Speciality</th>
                                <th className="header-item">Days</th>
                                <th className="header-item header-btn" />
                                <th className="header-item header-btn" />
                            </tr>
                            {itemsProjects()}
                        </table>
                )
                }
                {listProjects?.projects == undefined ?
                    <span /> :
                    <Pagination
                        className="admin-pagination"
                        page={filterParams.page || 1}
                        onChange={handlePageChange}
                        count={totalPages}
                    />
                }
            </div>
        </div>
    )
}

export default PendingProjects;