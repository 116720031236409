import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Swal from 'sweetalert2'

import Projects from '../../api/Projects';
import '../../assets/css/CardApplicant.css';

const CardApplicant = ({ firstName, lastName, email, _id, title, institution, showingApplicants, numberOfAcceptances, projectId }: {
    firstName: string,
    lastName: string,
    email: string,
    _id: string,
    title: string,
    institution: string,
    showingApplicants: string,
    numberOfAcceptances: number,
    projectId: any
}) => {
    const acceptApplicant = async (_id: string, projectId: string) => {
        if (numberOfAcceptances <= 3) {
            const authorization = localStorage.getItem("@fmr/userAuthorization")!;
            const dataApplicant = { userId: _id, projectId: projectId }
            await Projects.acceptApplicant(authorization, dataApplicant);
        } else {
            Swal.fire({
                title: 'Error',
                text: 'This user has exceeded the accepted application limit in one month (3)',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: "#1976d2"
            })
        }
    }

    const declineApplicant = async () => {
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const dataApplicant = { userId: _id, projectId: projectId }
        await Projects.declineApplicant(authorization, dataApplicant);
    }

    const removeAccepted = async () => {
        const authorization = localStorage.getItem("@fmr/userAuthorization")!;
        const dataApplicant = { userId: _id, projectId: projectId }
        await Projects.removeAccepted(authorization, dataApplicant);
    }

    return (
        <div id="card-applicant">
            <div className="card-title">
                <p className="applicant-name">{`${firstName} ${lastName}`}</p>
                <p className="applicant-title">{title}</p>
                <div className="card-info">
                    <p className="applicant-email"><strong>Email address: </strong>{email}</p>
                    <p className="message"><strong>Institution: </strong>{institution}</p>
                </div>
                {showingApplicants == "New" &&
                    <div className="card-actions">
                        <Button className="applicant-btn" variant="contained" onClick={() => acceptApplicant(_id, projectId)}>Accept</Button>
                        <Button className="applicant-btn" variant="outlined" onClick={declineApplicant}>Decline</Button>
                    </div>
                }
                {showingApplicants == "Accepted" &&
                    <Button className="remove-applicant-btn" variant="contained" onClick={removeAccepted}>Remove Accepted</Button>
                }
            </div>
        </div>
    )
}

export default CardApplicant;